import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventBusService } from '../../security/event-bus.service';
import { TokenStorageService } from '../../security/token-storage.service';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  
  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showClientBoard = false;
  showModeratorBoard = false;
  yOffset = 0;
  username?: string;
  eventBusSub?: Subscription;

  billeteraSelected = true;
  movimientoSelected = false;
  balanceSelected = false;
  plazoFijoSelected = false;

  constructor(private tokenStorageService: TokenStorageService, private eventBusService: EventBusService) { 
    window.onscroll = () => {
      this.yOffset = window.pageYOffset;
    };
  }

  ngOnInit(): void {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showClientBoard = this.roles.includes('ROLE_CLIENTE');
      this.username = user.username;
    }

    this.eventBusSub = this.eventBusService.on('logout', () => {
      this.logout();
    });
  }

  switcherClassApplied = false;
    switcherToggleClass() {
        this.switcherClassApplied = !this.switcherClassApplied;
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    logout(): void {
      this.tokenStorageService.signOut();
  
      this.isLoggedIn = false;
      this.roles = [];
      this.showAdminBoard = false;
      this.showModeratorBoard = false;
    }

    selectBilleteraFunction() {
      this.billeteraSelected = true;
      this.movimientoSelected = false;
      this.balanceSelected = false;
      this.plazoFijoSelected = false;
    }
    selectMovimientoFunction() {
      this.billeteraSelected = false;
      this.movimientoSelected = true;
      this.balanceSelected = false;
      this.plazoFijoSelected = false;
    }
    selectBalanceFunction() {
      this.billeteraSelected = false;
      this.movimientoSelected = false;
      this.balanceSelected = true;
      this.plazoFijoSelected = false;
    }
    selectPlazoFijoFunction() {
      this.billeteraSelected = false;
      this.movimientoSelected = false;
      this.balanceSelected = false;
      this.plazoFijoSelected = true;
    }
  
}
