export class BilleteraDTO {
  moneda:string;
  cantidad:number;
  precio:number;
  total:number;


  constructor( moneda: string,  cantidad: number,  precio: number,  total: number) {
    this.moneda =moneda;
    this.cantidad = cantidad;
    this.precio = precio;
    this.total = total;
  }
}
