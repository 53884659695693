import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MovimientoDTO } from 'src/app/class/movimiento/movimiento-dto';
import { EventBusService } from 'src/app/security/event-bus.service';
import { AdminService } from '../../service/admin.service';
import { DetalleMovimientoDTO } from 'src/app/class/detalleMobimiento/detalle-movimiento-dto';
import { DepositoDTO } from 'src/app/class/billetera/deposito-dto';
import { CompraVentaDeMoneda } from 'src/app/class/billetera/compra-venta-de-moneda';



@Component({
  selector: 'app-movimientos',
  templateUrl: './movimientos.component.html',
  styleUrls: ['./movimientos.component.css']
})
export class MovimientosComponent implements OnInit {

  movimientoMsj= "Click en Ver para ver los detalles de movimientos y modificar los datos ";
  displayedColumnsMovimiento: string[] = [ 'movimientoId','balanceId','fecha','transaccion','monto','ver'];
  detalleMovimientoSelected: DetalleMovimientoDTO[] = [new DetalleMovimientoDTO(0,0,'','','',0,0,0),new DetalleMovimientoDTO(0,0,'','','',0,0,0)];
  detalleMovimientoClear: DetalleMovimientoDTO=new DetalleMovimientoDTO(0,0,'','','',0,0,0);
  movimientoClear: MovimientoDTO=new MovimientoDTO(0,0,'','',0);
  isModificarDetalle1:boolean =true;
  isModificarDetalle1AndIsCompraOventa:boolean =false;
  isNotModificarDetalle1AndIsCompraOventa:boolean =false;

  mostrarBotonModificar = false;

  movimientoSelected:MovimientoDTO=new MovimientoDTO(0,0,'','',0);
  depositoModificadoRequest:DepositoDTO=new DepositoDTO('',0);
  compraOVentaModificadaRequest:CompraVentaDeMoneda=new CompraVentaDeMoneda('',0,0,'');
  movimientoModificarMSJ:string='';
  @ViewChild(MatTable) tablaMovimiento!: MatTable<MovimientoDTO>;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  dataSource!: MatTableDataSource<MovimientoDTO>;

  constructor(
    public dialogRef: MatDialogRef<MovimientosComponent>,
    @ Inject(MAT_DIALOG_DATA) public data:{movimiento: MovimientoDTO[],cliente:''}
    ,
    private adminService: AdminService, private eventBusService: EventBusService) {}

  ngOnInit() {
    this.reloadTable();
    registerLocaleData( es );
  }

  cancelar() {
    this.dialogRef.close();
  }
  relogearDatos(){
    this.adminService.getMovimientosDelClienteByEmail(this.data.cliente).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.movimientoMsj = JSON.parse(err.error).message;},    // errorHandler
        next: (data) => {
          this.data.movimiento = data;
          this.reloadTable();     // nextHandler
        }
      }
    );
    registerLocaleData( es );
    this.reloadTable();
  }

  getDetallesMovimientos(movimiento:MovimientoDTO){
    this.adminService.getDetallesMovimientosDelClienteByMovimientoIdAndEmail(movimiento.movimientoId,this.data.cliente).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.movimientoMsj = JSON.parse(err.error).message;},    // errorHandler
        next: (data) => {
          this.movimientoSelected=movimiento;
          if(movimiento.transaccion=='COMPRA'){
            this.isModificarDetalle1=true;
            this.isModificarDetalle1AndIsCompraOventa=true;
            this.isNotModificarDetalle1AndIsCompraOventa=false;
            this.movimientoModificarMSJ='Modifica de la linea verde los valores: MONEDA-CANTIDAD-MONTO. El precio se calcula solo'
          }
          else{
            if(movimiento.transaccion=='VENTA'){
              this.isModificarDetalle1=false;
              this.isModificarDetalle1AndIsCompraOventa=false;
              this.isNotModificarDetalle1AndIsCompraOventa=true;
              this.movimientoModificarMSJ='Modifica de la linea roja los valores: MONEDA-CANTIDAD-MONTO. El precio se calcula solo'
            }
            else if(movimiento.transaccion=='DEPOSITO'){
              this.isModificarDetalle1=false;
              this.isModificarDetalle1AndIsCompraOventa=true;
              this.isNotModificarDetalle1AndIsCompraOventa=true;
              this.movimientoModificarMSJ='Modifica de la linea verde los valores: MONTO. Los otros por defecto son en USDT'
            }
            else if(movimiento.transaccion=='RETIRO'){
              this.isModificarDetalle1=false;
              this.isModificarDetalle1AndIsCompraOventa=true;
              this.isNotModificarDetalle1AndIsCompraOventa=true;
              this.movimientoModificarMSJ='Modifica de la linea roja los valores: MONTO. Los otros por defecto son en USDT'
            }
          };
          this.movimientoMsj ='Fecha-'+movimiento.fecha+' NUM-'+movimiento.movimientoId+' TRANSACCION-'+movimiento.transaccion;
          this.detalleMovimientoSelected[0] =this.detalleMovimientoClear;
          this.detalleMovimientoSelected[1] =this.detalleMovimientoClear;
          this.mostrarBotonModificar = true;
          if(data[0]!=null){
            this.detalleMovimientoSelected[0] =data[0];
          }
          if(data[1]!=null){
            this.detalleMovimientoSelected[1] =data[1];
          }
          this.reloadTable();;     // nextHandler
        }
      }
    );
    registerLocaleData( es );
    this.reloadTable();
  }
  reloadTable(){
    this.dataSource = new MatTableDataSource<MovimientoDTO>(this.data.movimiento);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }
  filtrar(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filtro.trim().toLowerCase();
    this.movimientoMsj = "Tabla filtrada por : "+ filtro.trim().toLowerCase();
  }

  modificarDetalleMovimiento(){
    if (this.detalleMovimientoSelected[0].movimientoId==this.movimientoSelected.movimientoId){
    if (this.movimientoSelected.transaccion=='DEPOSITO'||this.movimientoSelected.transaccion=='RETIRO'){
      this.modificarDetalleMovimientoDepositoORetiro(this.detalleMovimientoSelected[0]);
    }
    else if (this.movimientoSelected.transaccion=='COMPRA'){
      if(this.detalleMovimientoSelected[0].transaccion=='INGRESO'){
        this.modificarDetalleMovimientoCompraOVenta(this.detalleMovimientoSelected[0]);
      }
      else if(this.detalleMovimientoSelected[1].transaccion=='INGRESO'){
        this.modificarDetalleMovimientoCompraOVenta(this.detalleMovimientoSelected[1]);
      }
    }
    else if (this.movimientoSelected.transaccion=='VENTA'){
      if(this.detalleMovimientoSelected[0].transaccion=='EGRESO'){
        this.modificarDetalleMovimientoCompraOVenta(this.detalleMovimientoSelected[0]);
      }
      else if(this.detalleMovimientoSelected[1].transaccion=='EGRESO'){
        this.modificarDetalleMovimientoCompraOVenta(this.detalleMovimientoSelected[1]);
      }
    }
    registerLocaleData( es );
    this.reloadTable();
  }}

  modificarDetalleMovimientoDepositoORetiro(detalleModificado:DetalleMovimientoDTO){
    this.depositoModificadoRequest=new DepositoDTO(this.data.cliente,detalleModificado.montoUsd);
    this.adminService.putDetallesMovimientosDepositoORetiroDelClienteByMovimientoIdAndEmail(detalleModificado.movimientoId,this.data.cliente, this.depositoModificadoRequest).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.movimientoMsj = JSON.parse(err.error).message;},    // errorHandler
        next: (data) => {
          this.movimientoSelected= this.movimientoClear;
          this.movimientoMsj =data;
          this.detalleMovimientoSelected[0] =this.detalleMovimientoClear;
          this.detalleMovimientoSelected[1] =this.detalleMovimientoClear;
          this.mostrarBotonModificar = false;
          this.reloadTable();;     // nextHandler
        }
      }
    );
  }
  modificarDetalleMovimientoCompraOVenta(detalleModificado:DetalleMovimientoDTO){
    this.compraOVentaModificadaRequest= new CompraVentaDeMoneda(this.data.cliente,detalleModificado.cantidad,detalleModificado.montoUsd,detalleModificado.moneda);
    this.adminService.putDetallesMovimientosCompraOVentaDelClienteByMovimientoIdAndEmail(detalleModificado.movimientoId,this.data.cliente,this.compraOVentaModificadaRequest).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.movimientoMsj = JSON.parse(err.error).message;},    // errorHandler
        next: (data) => {
          this.movimientoSelected= this.movimientoClear;
          this.movimientoMsj =data;
          this.detalleMovimientoSelected[0] =this.detalleMovimientoClear;
          this.detalleMovimientoSelected[1] =this.detalleMovimientoClear;
          this.mostrarBotonModificar = false;
          this.reloadTable();;     // nextHandler
        }
      }
    );
  }
}
