import { Component, OnInit } from '@angular/core';
import { ApiCoinService } from 'src/app/service/api-coin.service';
import { interval } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  btcToUsdt: number = 0;
  btcToUsdtPrev: number = 0;
  ethToUsdt: number = 0;
  ethToUsdtPrev: number = 0;
  constructor(
    private apiCoin: ApiCoinService
  ) { }

  ngOnInit(): void {

    interval(2000).subscribe(() => {
      this.apiCoin.getBtcToUsdt().subscribe(
        {
          complete: () => { },
          error: (err) => {
            throw err
          },
          next: (data) => {
            this.btcToUsdtPrev = this.btcToUsdt;
            this.btcToUsdt = data.USDT;
            console.log(this.btcToUsdt, this.btcToUsdtPrev )
          }
        }
      );
      this.apiCoin.getEthToUsdt().subscribe(
        {
          complete: () => { },
          error: (err) => {
            throw err
          },
          next: (data) => {
            this.ethToUsdt = data.USDT
          }
        }
      );
    })


  }

  

}
