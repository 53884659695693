<div class="col-md-12 contenedor">
  <div class="card card-container">
    <img *ngIf="!isLoggedIn" id="profile-img" src="/assets/img/icono.png" class="profile-img-card" />
    <form *ngIf="!isLoggedIn" name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
      <div class="form-group">
        <label for="email">Usuario</label>
        <input type="text" class="form-control" name="email" [(ngModel)]="form.email" required #email="ngModel" />
        <div class="alert alert-danger" role="alert" *ngIf="email.errors && f.submitted">
          Este campo es requerido!
        </div>
      </div>
      <div class="form-group">
        <label for="password">Clave</label>
        <input type="password" class="form-control" name="password" [(ngModel)]="form.password" required minlength="8"
          #password="ngModel" />
        <div class="alert alert-danger" role="alert" *ngIf="password.errors && f.submitted">
          <div *ngIf="form.controls.password.errors.required">Este campo es requerido</div>
          <div *ngIf="form.controls.password.errors.minlength">
            La contraseña tiene que tener como minimo 8 caracteres
          </div>
        </div>
      </div>
      <div class="form-group buttons">
        <button  class="default-btn" color="primary">
          INICIAR SESION
        </button>
      </div>
    </form>
  </div>
</div>