import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

const API_URL = "https://min-api.cryptocompare.com/data/price?fsym="

@Injectable({
  providedIn: 'root'
})
export class ApiCoinService {

  constructor(private http: HttpClient) { }

  getBtcToUsdt() {
    return this.http.get<any>(API_URL + "BTC" + "&tsyms=USDT", { responseType: 'json' });
  }

  getEthToUsdt() {
    return this.http.get<any>(API_URL + "ETH" + "&tsyms=USDT", { responseType: 'json' });
  }

}
