<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>
      <h1 mat-dialog-title>Billetera de <span class="resaltado">{{data.compraVentaDeMoneda.cliente}}</span></h1>

    </mat-card-title>
    <mat-card-subtitle>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #tablaBilletera matSort>

        <ng-container matColumnDef="moneda">
          <th mat-header-cell *matHeaderCellDef> Moneda </th>
          <td mat-cell *matCellDef="let element"> {{element.moneda }} </td>
          <td mat-footer-cell *matFooterCellDef> TOTAL DE TODAS</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="cantidad">
          <th mat-header-cell *matHeaderCellDef> Cantidad </th>
          <td mat-cell *matCellDef="let element"> {{element.cantidad | currency: 'USD' | lowercase }} </td>
          <td mat-footer-cell *matFooterCellDef> LAS MONEDAS</td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="precio">
          <th mat-header-cell *matHeaderCellDef> Precio en USDT</th>
          <td mat-cell *matCellDef="let element"> {{element.precio| currency: 'USD' | lowercase}} </td>
          <td mat-footer-cell *matFooterCellDef> EN USDT</td>
        </ng-container>


        <!-- Name Column -->
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef> Total </th>
          <td mat-cell *matCellDef="let element"> {{element.total | currency: 'USD' | lowercase}} </td>
          <td mat-footer-cell *matFooterCellDef>{{getMontoTotal() | currency: 'USD' | lowercase}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: true"></tr>
      </table>

    </div>
    <div mat-dialog-actions class="botones-accion">
      <button mat-button (click)="toggleComprarVenderCrypto()">Comprar/Vender Crypto</button>
      <button mat-button (click)="toggleDepositarUSDT()">Depositar/Retirar USDT</button>
      <button mat-button (click)="relogearDatos(data.inversion.cliente)"><i class="fa fa-refresh"></i></button>
    </div>
    <header class="jumbotron" *ngIf="isComprarVenderCrypto">
      <p>Comprar/Vender monedas</p>

      <h1 mat-dialog-title>{{venderOComprarMsj}}</h1>

      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="data.compraVentaDeMoneda.moneda" (ngModelChange)="changeMondeda($event)"
          type="text" placeholder="Moneda">
      </mat-form-field>


      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="data.compraVentaDeMoneda.cantidad" (ngModelChange)="changeCantidad($event)"
          type="number" placeholder="Cantidad">
      </mat-form-field>

      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="precio" (ngModelChange)="changeMonto($event)" type="number" placeholder="Precio">
      </mat-form-field>

      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="data.compraVentaDeMoneda.montoUsd" (ngModelChange)="changePrecio($event)"
          type="number" placeholder="Monto en USDT">
      </mat-form-field>
      <div mat-dialog-actions class="botones-comprar-vender">
        <button mat-button (click)="comprarCrypto(data.compraVentaDeMoneda)">Comprar</button>
        <button mat-button (click)="venderCrypto(data.compraVentaDeMoneda)">Vender</button>
      </div>
      <p>Si pones la cantidad y el monto el precio se actualiza solo, despues ajustas el precio y el monto se actualiza
        solo</p>
    </header>



    <header class="jumbotron" *ngIf="isDepositarRetirarUSDT">
      <p>Depositar / Retirar</p>

      <h1 mat-dialog-title>{{depositarRetirarUSDTMsj}}</h1>



      <mat-form-field class="twoFieldRow">
        <input matInput [(ngModel)]="data.deposito.email" type="text" readonly="readonly" placeholder="Email">
      </mat-form-field>

      <mat-form-field class="twoFieldRow">
        <input matInput [(ngModel)]="data.deposito.monto" type="number" placeholder="Monto">
      </mat-form-field>
      <div mat-dialog-actions class="botones-comprar-vender">
        <button mat-button (click)="depositar(data.deposito)">Depositar</button>
        <button mat-button (click)="retirar(data.deposito)">Retirar</button>
      </div>
    </header>




  </mat-card-content>

  <mat-card-footer>
    <button mat-button (click)="relogearDatos(data.inversion.cliente)"><i class="fa fa-refresh"></i></button>
    <button mat-button (click)="reloadTable()">reloadTable</button>
    <button mat-button (click)="cancelar()">Cerrar</button>
  </mat-card-footer>
</mat-card>