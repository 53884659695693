<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>
      <h1 mat-dialog-title>Balances
      </h1>
    </mat-card-title>
    <mat-card-subtitle>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div style="margin:1rem auto; width: 90%; height: 90%;padding: 1rem;">



      <mat-form-field>
        <mat-label>Buscar</mat-label>
        <input matInput (keyup)="filtrar($event)" placeholder="buscar">
      </mat-form-field>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #tablaBalance matSort
        matSortActive="balanceId" matSortDirection="desc" matSortDisableClear>

        <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

        <!-- Position Column -->
        <ng-container matColumnDef="balanceId">
          <th mat-header-cell *matHeaderCellDef> Balance Id </th>
          <td mat-cell *matCellDef="let balance"> {{balance.balanceId}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="fechaInicio">
          <th mat-header-cell *matHeaderCellDef> Fecha Inicio </th>
          <td mat-cell *matCellDef="let balance"> {{balance.fechaInicio}} </td>
        </ng-container>


        <!-- Name Column -->
        <ng-container matColumnDef="fechaCierre">
          <th mat-header-cell *matHeaderCellDef> Fecha Cierre</th>
          <td mat-cell *matCellDef="let balance"> {{balance.fechaCierre}} </td>
        </ng-container>


        <!-- Name Column -->
        <ng-container matColumnDef="montoInvertido">
          <th mat-header-cell *matHeaderCellDef> Monto Invertido </th>
          <td mat-cell *matCellDef="let balance"> ${{balance.montoInvertido| number:'1.2-2':'es'}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="capitalizacionBruta">
          <th mat-header-cell *matHeaderCellDef> Capitalizacion Bruta </th>
          <td mat-cell *matCellDef="let balance"> ${{balance.capitalizacionBruta| number:'1.2-2':'es'}} </td>
        </ng-container>


        <!-- Name Column -->
        <ng-container matColumnDef="comision">
          <th mat-header-cell *matHeaderCellDef> Comision </th>
          <td mat-cell *matCellDef="let balance"> ${{balance.comision| number:'1.2-2':'es'}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="capitalizacionNeta">
          <th mat-header-cell *matHeaderCellDef> Capitalizacion Neta </th>
          <td mat-cell *matCellDef="let balance"> ${{balance.capitalizacionNeta| number:'1.2-2':'es'}} </td>
        </ng-container>


        <!-- Name Column -->
        <ng-container matColumnDef="balanceNeto">
          <th mat-header-cell *matHeaderCellDef> Balance Neto </th>
          <td mat-cell *matCellDef="let balance"> ${{balance.balanceNeto| number:'1.2-2':'es'}} </td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumnsBalance"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsBalance;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
    </div>

  </mat-card-content>

  <mat-card-footer>
    <div mat-dialog-actions>
      <button mat-button  (click)="relogearDatos()"><i class="fa fa-refresh"></i></button>
      <button mat-button  (click)="cerrarBalance()">Cerrar Balance</button>
      <button mat-button  (click)="cancelar()">Cerrar</button>
    </div>
  </mat-card-footer>
</mat-card>