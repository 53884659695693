export class NuevoPlazoFijoDTO {
    montoInvertido: number;
    interesAnual: number;
    fechaDeInicio: string;

    constructor(montoInvertido: number, interesAnual: number, fechaDeInicio: string) {
        this.montoInvertido = montoInvertido;
        this.interesAnual = interesAnual;
        this.fechaDeInicio = fechaDeInicio;
    }
}
