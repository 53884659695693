<div class="container">
  <div class="buscar-container">
    <mat-form-field>
      <mat-label style="color: white;">Buscar</mat-label>
      <input matInput (keyup)="filtrar($event)" >
    </mat-form-field>
    <div>
      <button mat-stroked-button color="primary" (click)="reloadTable1()"><i class="fa fa-refresh"></i></button>
      <button mat-stroked-button color="primary" (click)="abrirDialogo()"><i class='fas fa-plus fa-lg'></i></button>
    </div>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #tabla1 matSort>


    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Usuario</th>
      <td mat-cell *matCellDef="let cliente"> {{cliente.email}} </td>
    </ng-container>


    <ng-container matColumnDef="nombreCompleto">
      <th mat-header-cell *matHeaderCellDef mat-sort-header >Nombre completo</th>
      <td mat-cell *matCellDef="let cliente"> {{cliente.nombreCompleto}} </td>
    </ng-container>


    <ng-container matColumnDef="editar">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let cliente">
        <button mat-button  focusable="false" (click)="editarClientePerfil(cliente.email)">
          Perfil
        </button>
      </td>
    </ng-container>


    <ng-container matColumnDef="billetera">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let cliente">
        <button mat-button  focusable="false" (click)="billeteraDelCliente(cliente.email)">
          Billetera
        </button>
      </td>
    </ng-container>


    <ng-container matColumnDef="historialInversion" >
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="media-cell"></th>
      <td mat-cell *matCellDef="let cliente" class="media-cell">
        <button mat-button  focusable="false" (click)="verMovimientos(cliente.email)">
          Movimientos
        </button>
      </td>
    </ng-container>


    <ng-container matColumnDef="historialBalance">
      <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
      <td mat-cell *matCellDef="let cliente">
        <button mat-button  focusable="false" (click)="verBalance(cliente.email)">
          Balance
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="plazoFijo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="media-cell-sm"></th>
      <td mat-cell *matCellDef="let cliente " class="media-cell-sm">
        <button mat-button  focusable="false"
          (click)="abrirDialogoPlazosFijosDelCliente(cliente.email)">
          Plazo Fijo
        </button>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="columnas"></tr>
    <tr mat-row *matRowDef="let row; columns: columnas;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>
</div>