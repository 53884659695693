import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ClientePerfilDetallado } from 'src/app/class/cliente/cliente-perfil-detallado';
import { EventBusService } from 'src/app/security/event-bus.service';
import { AdminService } from '../../service/admin.service';


@Component({
  selector: 'app-update-cliente-dialog',
  templateUrl: './update-cliente-dialog.component.html',
  styleUrls: ['./update-cliente-dialog.component.css']
})
export class UpdateClienteDialogComponent implements OnInit {
  content?: string;


  constructor(
    public dialogRef: MatDialogRef<UpdateClienteDialogComponent>,
    @ Inject(MAT_DIALOG_DATA) public cliente: ClientePerfilDetallado,
    private adminService: AdminService, private eventBusService: EventBusService) {}

  ngOnInit() {
  }

  cancelar() {
    this.dialogRef.close();
  }


  eliminarCliente() { 
    if (confirm("¿Estas seguro de borrar el cliente?") == true){
      this.adminService.eliminarCliente(this.cliente.email).subscribe(
        {
          complete: () => {  }, // completeHandler
          error: (err) => {
            this.content = JSON.parse(err.error).message;},    // errorHandler
          next: (data) => {
            this.content= data;
          }
        }
      );
      this.dialogRef.close();
    }

    
  }

}
