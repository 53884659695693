import { NgModule, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatCardModule } from '@angular/material/card';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSortModule } from '@angular/material/sort';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule  } from '@angular/material/core';
// import { MatMomentDateModule } from '@angular/material-moment-adapter';

import { authInterceptorProviders } from './security/auth.interceptor';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './public/home/home.component';
import { LoginComponent } from './public/login/login.component';
import { RegisterComponent } from './public/register/register.component';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { StickyNavModule } from 'ng2-sticky-nav';

import { UpdateClienteDialogComponent } from './admin/clientDialog/update-cliente-dialog/update-cliente-dialog.component';
import { NuevoClienteDialogComponent } from './admin/clientDialog/nuevo-cliente-dialog/nuevo-cliente-dialog.component';
import { BilleteraDTOClienteDialogoComponent } from './admin/clientDialog/billetera-dtocliente-dialogo/billetera-dtocliente-dialogo.component';
import { ClienteBilleteraComponent } from './cliente/cliente-billetera/cliente-billetera.component';
import { ClienteBalanceComponent } from './cliente/cliente-balance/cliente-balance.component';
import { ClienteMovimientosComponent } from './cliente/cliente-movimientos/cliente-movimientos.component';
import { ClienteDetalleMovimientoComponent } from './cliente/cliente-detalle-movimiento/cliente-detalle-movimiento.component';
import { MovimientosComponent } from './admin/clientDialog/movimientos/movimientos.component';
import { BalanceComponent } from './admin/clientDialog/balance/balance.component';
import { CerrarBalanceComponent } from './admin/clientDialog/cerrar-balance/cerrar-balance.component';
import { ConfirmarDialogComponent } from './admin/clientDialog/confirmar-dialog/confirmar-dialog.component';
import { ClientePlazoFijoComponent } from './cliente/cliente-plazo-fijo/cliente-plazo-fijo.component';
import { PlazoFijoComponent } from './admin/clientDialog/plazo-fijo/plazo-fijo.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MainComponent } from './components/main/main.component';
import { FooterComponent } from './components/footer/footer.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    RegisterComponent,
    AdminHomeComponent,
    UpdateClienteDialogComponent,
    NuevoClienteDialogComponent,
    BilleteraDTOClienteDialogoComponent,
    ClienteBilleteraComponent,
    ClienteBalanceComponent,
    ClienteMovimientosComponent,
    ClienteDetalleMovimientoComponent,
    MovimientosComponent,
    BalanceComponent,
    CerrarBalanceComponent,
    ConfirmarDialogComponent,
    ClientePlazoFijoComponent,
    PlazoFijoComponent,
    NavbarComponent,
    MainComponent,
    FooterComponent,
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
    NO_ERRORS_SCHEMA
  ],
  imports: [
    BrowserModule,
    StickyNavModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    MatCardModule,
    MatButtonModule,
    MatTableModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule 
    // MatMomentDateModule
  ],
  providers: [authInterceptorProviders, MatDatepickerModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
