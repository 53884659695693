import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { BilleteraDTO } from 'src/app/class/billetera/billetera-dto';
import { EventBusService } from 'src/app/security/event-bus.service'
import { BinanceCoins } from 'src/app/class/coins/binance-coins';
import { AdminService } from '../../service/admin.service';
import { EventData } from 'src/app/security/event.class';
import { PublicService } from 'src/app/public/service/public.service';
import { ConfirmarDialogComponent } from '../confirmar-dialog/confirmar-dialog.component';
import { CierreBalance } from 'src/app/class/coins/cierre-balance';
import { MonedasPrecio } from 'src/app/class/coins/monedas-precio';
@Component({
  selector: 'app-cerrar-balance',
  templateUrl: './cerrar-balance.component.html',
  styleUrls: ['./cerrar-balance.component.css']
})
export class CerrarBalanceComponent implements OnInit {

  depositarRetirarUSDTMsj= "El deposito y retiro se aplican a los USDT del cliente.";
  buscarPrecio= "El precio de la moneda es a valor USDT.";
  displayedColumns: string[] = [ 'moneda','cantidad','precio','total'];
  binanceCoin: BinanceCoins={symbol:'',price:'0'};
  monedaEncontrada:boolean=false;
  precio:number=0;
  mondeaExistente=false;
  total:number=0;
  isComprarVenderCrypto=true;
  isDepositarRetirarUSDT=false;
  billetera: BilleteraDTO[]=[];

  constructor(
    public dialogRef: MatDialogRef<CerrarBalanceComponent>,
    @ Inject(MAT_DIALOG_DATA) public data:{billetera: BilleteraDTO[],cliente:''}
    ,private adminService: AdminService,public dialogo: MatDialog,
    private publicService: PublicService, private eventBusService: EventBusService) {}


    ngOnInit() {
            this.data.billetera.forEach(moneda=> this.setmondedaPrecioYTotal(moneda));
            this.relogearDatos();
            this.reloadTable();  // nextHandler
       registerLocaleData( es );}     // nextHandler


    cancelar() {
      this.dialogRef.close();
    }
    relogearDatos(){
      this.billetera=[];
      this.adminService.getBilleteraDelClienteByEmail(this.data.cliente).subscribe(
        {
          complete: () => {  }, // completeHandler
          error: (err) => {
            this.buscarPrecio = err.error.message || err.error || err.message;

            if (err.status === 403)
              this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
          next: (data) => {
            this.data.billetera=data;
            this.data.billetera.forEach(moneda=> this.setmondedaPrecioYTotal(moneda));
            this.reloadTable();},     // nextHandler
      }

      );
      this.data.billetera.forEach(moneda=> this.setmondedaPrecioYTotal(moneda));
      this.reloadTable();
      registerLocaleData( es );
      this.reloadTable();
    }

    reloadTable(){
      this.total=0;
      this.data.billetera.forEach(m=> this.total= this.total+m.total);
      this.billetera = this.data.billetera;

    }


    setmondedaPrecioYTotal(moneda: BilleteraDTO): void {
      this.binanceCoin = this.serchMoneda(moneda.moneda);
    }
    serchMoneda(moneda: string): BinanceCoins {
      this.mondeaExistente=true
      if(moneda=='USDT'){
        var foundIndex = this.data.billetera.findIndex(x => x.moneda == moneda);
        this.data.billetera[foundIndex] =new BilleteraDTO(moneda,this.data.billetera[foundIndex].cantidad,1,this.data.billetera[foundIndex].cantidad*1);
          this.reloadTable();
      }
      else if (this.mondeaExistente){
        this.monedaEncontrada= this.buscarPrecioEnBinanceReturn(moneda)
      }
      if (!this.monedaEncontrada){
        this.binanceCoin= this.buscarPrecioEnOKXReturn(moneda)

      }
      return this.binanceCoin ;
    }
    buscarPrecioEnOKXReturn(monedaValue: string): BinanceCoins{
      this.publicService.getCurrentCoinOKX(monedaValue).subscribe(
        {
          complete: () => {  }, // completeHandler
          error: (err) => {
            this.binanceCoin={symbol:'',price:'0'};},    // errorHandler
          next: (data) => {
            var foundIndex = this.data.billetera.findIndex(x => x.moneda == monedaValue);
            this.data.billetera[foundIndex] =new BilleteraDTO(monedaValue,this.data.billetera[foundIndex].cantidad,+data.price,this.data.billetera[foundIndex].cantidad*+data.price);
            this.reloadTable();
          }
        }
      );
      return this.binanceCoin;

    }

    buscarPrecioEnBinanceReturn(monedaValue: string): boolean {
      this.publicService.getCurrentCoin(monedaValue).subscribe(
        {
          complete: () => {  }, // completeHandler
          error: (err) => {
            this.monedaEncontrada=false;
            this.binanceCoin={symbol:'',price:'0'};},    // errorHandler
          next: (data) => {
            this.monedaEncontrada=true;
            var foundIndex = this.data.billetera.findIndex(x => x.moneda == monedaValue);
            this.data.billetera[foundIndex] =new BilleteraDTO(monedaValue,this.data.billetera[foundIndex].cantidad,+data.price,this.data.billetera[foundIndex].cantidad*+data.price);
            this.reloadTable();
          }
        }
      );

      return this.monedaEncontrada;
    }

    changeMonto(moneda:BilleteraDTO,montoValue: number){
      var foundIndex = this.data.billetera.findIndex(x => x.moneda == moneda.moneda);
      this.data.billetera[foundIndex] =new BilleteraDTO(moneda.moneda,moneda.cantidad,montoValue/moneda.cantidad,montoValue);
      this.reloadTable();

    }
    modificarDetalleMovimiento(){

    this.dialogo
    .open(ConfirmarDialogComponent, {
      data: `¿Estas seguro de cerrar el balance?`,
      height: '250px',
      width: '300px'
    })
    .afterClosed()
    .subscribe((confirmado: Boolean) => {
      if (confirmado) {
        var monedasPrecio:MonedasPrecio[]=[];
        this.data.billetera.forEach(m=> monedasPrecio.push(new MonedasPrecio(m.moneda,m.precio)));
        var cierreBalance: CierreBalance= new CierreBalance(this.data.cliente,monedasPrecio);
        this.adminService.cerrarBalanceDelCliente(cierreBalance).subscribe(
          {
            complete: () => {  }, // completeHandler
            error: (err) => {},    // errorHandler
            next: (data) => {
              alert("¡Cerrado! No te confundas de cerrarlo otra vez. Las monedas que ves son las que mantuvo el cliente");

            }
          }
        );
        this.dialogRef.close();
      } else {
        alert("No se realizo el cierre");
      }
    });
   }


}

