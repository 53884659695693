import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventBusService } from './security/event-bus.service';
import { TokenStorageService } from './security/token-storage.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('fadeInOut', [
      state('visible', style({opacity: 1})),
      state('hidden', style({opacity: 0})),
      transition('hidden => visible', animate('1000ms ease-in'))
 
    ],)
  ]
})
export class AppComponent implements OnInit, OnDestroy {
  title(title: any) {
    throw new Error('Method not implemented.');
  }
  private roles: string[] = [];
  isLoggedIn = false;
  showAdminBoard = false;
  showClientBoard = false;
  showModeratorBoard = false;
  isLoading = true;

  billeteraSelected = true;
  movimientoSelected = false;
  balanceSelected = false;
  plazoFijoSelected = false;

  username?: string;

  eventBusSub?: Subscription;

  constructor(private tokenStorageService: TokenStorageService, private eventBusService: EventBusService) { }

  ngOnInit(): void {
    setTimeout(()=>{
      this.isLoading = false
    }, 1500)
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.roles = user.roles;
      this.showAdminBoard = this.roles.includes('ROLE_ADMIN');
      this.showClientBoard = this.roles.includes('ROLE_CLIENTE');
      this.username = user.username;
    }

    this.eventBusSub = this.eventBusService.on('logout', () => {
      this.logout();
    });
  }

  ngOnDestroy(): void {
    if (this.eventBusSub)
      this.eventBusSub.unsubscribe();
  }

  logout(): void {
    this.tokenStorageService.signOut();

    this.isLoggedIn = false;
    this.roles = [];
    this.showAdminBoard = false;
    this.showModeratorBoard = false;
  }

  getUrl() {
    return "url('http://estringsoftware.com/wp-content/uploads/2017/07/estring-header-lowsat.jpg')";
  }

 

  selectBilleteraFunction() {
    this.billeteraSelected = true;
    this.movimientoSelected = false;
    this.balanceSelected = false;
    this.plazoFijoSelected = false;
  }
  selectMovimientoFunction() {
    this.billeteraSelected = false;
    this.movimientoSelected = true;
    this.balanceSelected = false;
    this.plazoFijoSelected = false;
  }
  selectBalanceFunction() {
    this.billeteraSelected = false;
    this.movimientoSelected = false;
    this.balanceSelected = true;
    this.plazoFijoSelected = false;
  }
  selectPlazoFijoFunction() {
    this.billeteraSelected = false;
    this.movimientoSelected = false;
    this.balanceSelected = false;
    this.plazoFijoSelected = true;
  }
}

