<div class="container">
  <div class="billetera-container">
    <h1>Billetera</h1>
    <div>
      <p>Monto Invertido: ${{montoInvertido| number:'1.2-2':'es'}}</p>
      <p>Monto Actual: ${{montoActual| number:'1.2-2':'es'}}</p>
      <p>Balance Actual: <span
          [ngStyle]="{'color':!totalPositivo ? 'rgb(133, 11, 11)' : 'rgb(27 182 56)'}">${{(montoActual-montoInvertido)|
          number:'1.2-2':'es'}}</span></p>

    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #tablaBilletera matSort>

      <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="moneda">
        <th mat-header-cell *matHeaderCellDef> Moneda </th>
        <td mat-cell *matCellDef="let element"> {{element.moneda}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="cantidad">
        <th mat-header-cell *matHeaderCellDef> Cantidad </th>
        <td mat-cell *matCellDef="let element"> {{element.cantidad | number:'1.2-2':'es'}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="precio">
        <th mat-header-cell [hidden]="celular" *matHeaderCellDef> En USDT</th>
        <td mat-cell [hidden]="celular" *matCellDef="let element"> ${{element.precio| number:'1.4-4':'es'}} </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef> Total </th>
        <td mat-cell *matCellDef="let element"> ${{element.total| number:'1.2-2':'es'}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">

      </tr>
    </table>
    <div class="contenedor-boton">
      <button mat-button color="primary" (click)="relogearDatos()"><i class="fa fa-refresh"></i></button>
    </div>
  </div>
</div>