import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

const AUTH_API =  environment.backendURL+'/api/auth/';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<any> {
    return this.http.post(AUTH_API + 'signin', {
      email,
      password
    }, httpOptions);
  }

  register(email: string, nombre: string, apellido: string, password: string,
    confirmPassword: string): Observable<any> {
    return this.http.post(AUTH_API + 'signup', {
      email, nombre, apellido, password,confirmPassword
    }, httpOptions);
  }
  registerAdmin(email: string, nombre: string, apellido: string, password: string,
    confirmPassword: string): Observable<any> {
    return this.http.post(AUTH_API + 'signup/admin', {
      email, nombre, apellido, password,confirmPassword
    }, httpOptions);
  }

  refreshToken(token: string) {
    return this.http.post(AUTH_API + 'refreshtoken', {
      refreshToken: token
    }, httpOptions);
  }
}
