import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { BilleteraDTO } from 'src/app/class/billetera/billetera-dto';
import { BinanceCoins } from 'src/app/class/coins/binance-coins';
import { EventBusService } from 'src/app/security/event-bus.service';
import { EventData } from 'src/app/security/event.class';
import { ClientService } from '../service/cliente.service';
import { PublicService } from 'src/app/public/service/public.service';

@Component({
  selector: 'app-cliente-billetera',
  templateUrl: './cliente-billetera.component.html',
  styleUrls: ['./cliente-billetera.component.css']
})
export class ClienteBilleteraComponent implements OnInit {

  content!:string;
  montoInvertido:number=0;
  montoActual:number=0;
  billetera:BilleteraDTO[]=[];
  displayedColumns: string[] = [ 'moneda','cantidad','precio','total'];
  binanceCoins:BinanceCoins[] = [];
  binanceCoin: BinanceCoins={symbol:'',price:'0'};
  precio:number=0;
  mondeaExistente=false;
  total:number=0;
  isComprarVenderCrypto=true;
  isDepositarRetirarUSDT=false;
  totalPositivo=false;
  celular=false;
  monedaEncontrada:boolean=false;

  @ViewChild(MatTable) tablaBilletera!: MatTable<BilleteraDTO>;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  dataSource!: MatTableDataSource<BilleteraDTO>;


  constructor(private publicService: PublicService,private clientService: ClientService, private eventBusService: EventBusService) { }
  ngOnInit(): void {
    this.relogearDatos();
   registerLocaleData( es );
   this.montoActual>=this.montoInvertido? this.totalPositivo=true:this.totalPositivo=false;

  }
  getMontoInvertido(){

    this.clientService.getMontoInvertido().subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.content = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (data) => {
          this.montoInvertido=data;
          this.content = "Se actualizo el monto invertido";
    }

  }
    );
  }
  relogearDatos(){
    this.billeteraDelCliente();
    this.getMontoInvertido();

    this.publicService.getCurrentCoins().subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.content = JSON.parse(err.error).message;},    // errorHandler
        next: (data) => {
          this.binanceCoins = data;
          this.total=0;
          this.billetera.forEach(moneda=> this.setmondedaPrecioYTotal(moneda))
          this.reloadTable();     // nextHandler
        }
      }
    );
    registerLocaleData( es );
    this.reloadTable();
    this.reloadTable();  // nextHandler
    this.montoActual>=this.montoInvertido? this.totalPositivo=true:this.totalPositivo=false;

  }
  reloadTable(){
    this.montoActual=0;
    this.billetera.forEach(m=> this.montoActual= this.montoActual+m.total);
    this.dataSource = new MatTableDataSource<BilleteraDTO>(this.billetera);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.montoActual>=this.montoInvertido? this.totalPositivo=true:this.totalPositivo=false;


  }
  billeteraDelCliente(){
    this.clientService.getBilleteraDelCliente().subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.content = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (data) => {
          this.billetera=data;
          this.content = "Se actualizo los datos de la billetera";
    }

  }
    );
  }
  getMontoTotal() {
    return this.total;
  }

  setmondedaPrecioYTotal(moneda: BilleteraDTO): void {
    this.binanceCoin = this.serchMoneda(moneda.moneda);
  }
  serchMoneda(moneda: string): BinanceCoins {
    this.mondeaExistente=true
    if(moneda=='USDT'){
      var foundIndex = this.billetera.findIndex(x => x.moneda == moneda);
      this.billetera[foundIndex] =new BilleteraDTO(moneda,this.billetera[foundIndex].cantidad,1,this.billetera[foundIndex].cantidad*1);
        this.reloadTable();
    }
    else if (this.mondeaExistente){
      this.monedaEncontrada= this.buscarPrecioEnBinanceReturn(moneda)
    }
    if (!this.monedaEncontrada){
      this.binanceCoin= this.buscarPrecioEnOKXReturn(moneda)

    }
    return this.binanceCoin ;
  }


  buscarPrecioEnOKXReturn(monedaValue: string): BinanceCoins{
    this.publicService.getCurrentCoinOKX(monedaValue).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.binanceCoin={symbol:'',price:'0'};},    // errorHandler
        next: (data) => {
          var foundIndex = this.billetera.findIndex(x => x.moneda == monedaValue);
          this.billetera[foundIndex] =new BilleteraDTO(monedaValue,this.billetera[foundIndex].cantidad,+data.price,this.billetera[foundIndex].cantidad*+data.price);
          this.reloadTable();
        }
      }
    );
    return this.binanceCoin;

  }

  buscarPrecioEnBinanceReturn(monedaValue: string): boolean {
    this.publicService.getCurrentCoin(monedaValue).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.monedaEncontrada=false;
          this.binanceCoin={symbol:'',price:'0'};},    // errorHandler
        next: (data) => {
          this.monedaEncontrada=true;
          var foundIndex = this.billetera.findIndex(x => x.moneda == monedaValue);
          this.billetera[foundIndex] =new BilleteraDTO(monedaValue,this.billetera[foundIndex].cantidad,+data.price,this.billetera[foundIndex].cantidad*+data.price);
          this.reloadTable();
        }
      }
    );

    return this.monedaEncontrada;
  }
}
