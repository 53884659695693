import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BinanceCoins } from 'src/app/class/coins/binance-coins';
import { environment } from 'src/environments/environment';

const API_URL =  environment.backendURL+'/api/public';

@Injectable({
  providedIn: 'root'
})
export class PublicService {
  constructor(private http: HttpClient) { }


  getCurrentCoin(monedaValue: string): Observable<any> {
    return this.http.get<BinanceCoins[]>(API_URL + '/binance/moneda/'+monedaValue+'USDT', { responseType: 'json' });
  }

  getCurrentCoinOKX(monedaValue: string): Observable<any> {
    return this.http.get<BinanceCoins[]>(API_URL + '/okx/moneda/'+monedaValue, { responseType: 'json' });
  }
  getPublicContent(): Observable<any> {
    return this.http.get(API_URL, { responseType: 'text' });
  }
  getCurrentCoins(): Observable<any> {
    return this.http.get<BinanceCoins[]>(API_URL + '/binance/moneda', { responseType: 'json' });
  }
}
