<div class="container">
  <mat-card-subtitle>

    <h1 mat-dialog-title>{{movimientoMsj}}</h1>
    <div *ngIf="detalleMovimientoSelected[0].moneda!=''"
      [ngStyle]="{'background-color': detalleMovimientoSelected[0].transaccion === 'INGRESO' ? '#00ff9811' : '#ff005f11'}"
      style="color: white">
      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="detalleMovimientoSelected[0].moneda" type="text" placeholder="Moneda" readonly>
      </mat-form-field>
      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="detalleMovimientoSelected[0].cantidad" type="number" placeholder="Cantidad"
          readonly>
      </mat-form-field>
      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="detalleMovimientoSelected[0].precio" type="number" placeholder="Precio" readonly>
      </mat-form-field>
      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="detalleMovimientoSelected[0].montoUsd" type="number" placeholder="MontoUsd"
          readonly>
      </mat-form-field>
    </div>
    <div style="color: white" *ngIf="detalleMovimientoSelected[1].moneda!='' && detalleMovimientoSelected[1].moneda!=null "
      [ngStyle]="{'background-color': detalleMovimientoSelected[1].transaccion === 'INGRESO' ? '#00ff9811' : '#ff005f11'}">
      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="detalleMovimientoSelected[1].moneda" type="text" placeholder="Moneda" readonly>
      </mat-form-field>
      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="detalleMovimientoSelected[1].cantidad" type="number" placeholder="Cantidad"
          readonly>
      </mat-form-field>
      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="detalleMovimientoSelected[1].precio" type="number" placeholder="Precio" readonly>
      </mat-form-field>
      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="detalleMovimientoSelected[1].montoUsd" type="number" placeholder="MontoUsd"
          readonly>
      </mat-form-field>

    </div>
    <div mat-dialog-content>



      <mat-form-field style="color: white;">
        <mat-label style="color: white;">Buscar</mat-label>
        <input matInput (keyup)="filtrar($event)" style="color:white;">
      </mat-form-field>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #tablaMovimiento matSort
        matSortActive="movimientoId" matSortDirection="desc" matSortDisableClear>

        <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->



        <!-- Name Column -->
        <ng-container matColumnDef="fecha">
          <th mat-header-cell *matHeaderCellDef> Fecha</th>
          <td mat-cell *matCellDef="let movimiento"> {{movimiento.fecha | slice:2:10}} </td>
        </ng-container>


        <!-- Name Column -->
        <ng-container matColumnDef="transaccion">
          <th mat-header-cell *matHeaderCellDef> Transaccion </th>
          <td mat-cell *matCellDef="let movimiento"> {{movimiento.transaccion}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="ver">
          <th mat-header-cell *matHeaderCellDef> Ver </th>
          <td mat-cell *matCellDef="let movimiento"> <button mat-button color="primary" focusable="false"
              (click)="getDetallesMovimientos(movimiento)">
              ver
            </button> </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsMovimiento"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsMovimiento;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>

      <div mat-dialog-actions class="contenedor-boton">
        <button mat-button color="primary" (click)="relogearDatos()"><i class="fa fa-refresh"></i></button>
      </div>
    </div>
  </mat-card-subtitle>
</div>