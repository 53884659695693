import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MovPlazoFijoDTO } from 'src/app/class/plazoFijo/movPlazoFijo-dto';
import { PlazoFijoDTO } from 'src/app/class/plazoFijo/plazoFijo-dto';
import { EventBusService } from 'src/app/security/event-bus.service';
import { ClientService } from '../service/cliente.service';

@Component({
  selector: 'app-cliente-plazo-fijo',
  templateUrl: './cliente-plazo-fijo.component.html',
  styleUrls: ['./cliente-plazo-fijo.component.css']
})
export class ClientePlazoFijoComponent implements OnInit {
  displayedColumnsPlazoFijoAbierto: string[] = ['fechaDeInicio', 'montoInvertido', 'mesesCumplidos', 'interesAnual', 'montoAcumuladoMensual', 'gananciaNeta'];
  displayedColumnsPlazoFijoCerrado: string[] = ['fechaDeInicio', 'fechaDeCierre', 'mesesCumplidos', 'interesAnual', 'gananciaNeta'];
  displayedColumnsMovPlazoFijo: string[] = ['fechaDeCreacion', 'operacion', 'monto', 'transaccion'];

  plazoFijoMsj = "";
  movPlazoFijoMsj = "";

  plazoFijoCerrado: PlazoFijoDTO[] = [];
  plazoFijoAbierto: PlazoFijoDTO[] = [];
  movPlazoFijo: MovPlazoFijoDTO[] = [];

  montoInvertido: number = 0;

  mostrarMovPlazoFijo = false;
  mostrarPlazoFijo = true;

  @ViewChild(MatTable) tablaPlazoFijoCerrado!: MatTable<PlazoFijoDTO>;
  @ViewChild(MatTable) tablaPlazoFijoAbierto!: MatTable<PlazoFijoDTO>;
  @ViewChild(MatTable) tablaMovPlazoFijo!: MatTable<MovPlazoFijoDTO>;
  @ViewChild('paginatorPlazoFijoCerrado') paginatorPlazoFijoCerrado!: MatPaginator;
  @ViewChild('paginatorPlazoFijoAbierto') paginatorPlazoFijoAbierto!: MatPaginator;
  @ViewChild('paginatorMovPlazoFijo') paginatorMovPlazoFijo!: MatPaginator;
  @ViewChild('sortPlazoFijoAbierto') sortPlazoFijoCerrado!: MatSort;
  @ViewChild('sortPlazoFijoAbierto') sortPlazoFijoAbierto!: MatSort;
  @ViewChild('sortMovPlazoFijo') sortMovPlazoFijo!: MatSort;

  dataSourcePlazoFijoCerrado!: MatTableDataSource<PlazoFijoDTO>;
  dataSourceMovPlazoFijo!: MatTableDataSource<MovPlazoFijoDTO>;
  dataSourcePlazoFijoAbierto!: MatTableDataSource<PlazoFijoDTO>;
  constructor(
    private clientService: ClientService, 
    private eventBusService: EventBusService) { }

  ngOnInit(): void {
    this.clientService.getPlazosFijosDelCliente().subscribe(
      {
        complete: () => { }, // completeHandler
        error: (err) => {
          this.plazoFijoMsj = JSON.parse(err.error).message;
        },    // errorHandler
        next: (data) => {
          data.forEach((value) => {
            if (value.fechaDeCierre == null) {
              this.plazoFijoAbierto.push(value);
              this.montoInvertido = value.montoInvertido
            } else {
              this.plazoFijoCerrado.push(value)
            }
          })
          this.reloadTable();
        }
      }
    );
    this.clientService.getMovPlazoFijoDelCliente().subscribe(
      {
        complete: () => { }, // completeHandler
        error: (err) => {
          this.movPlazoFijoMsj = JSON.parse(err.error).message;
        },    // errorHandler
        next: (data) => {
          this.movPlazoFijo = data;
          this.reloadTable();     // nextHandler
        }
      }
    );
  }
  reloadTable() {
    this.dataSourcePlazoFijoCerrado = new MatTableDataSource<PlazoFijoDTO>(this.plazoFijoCerrado);
    this.dataSourceMovPlazoFijo = new MatTableDataSource<MovPlazoFijoDTO>(this.movPlazoFijo);
    this.dataSourcePlazoFijoAbierto = new MatTableDataSource<PlazoFijoDTO>(this.plazoFijoAbierto);
    this.dataSourcePlazoFijoCerrado.sort = this.sortPlazoFijoCerrado;
    this.dataSourceMovPlazoFijo.sort = this.sortMovPlazoFijo;
    this.dataSourcePlazoFijoAbierto.sort = this.sortPlazoFijoAbierto;
    this.dataSourcePlazoFijoCerrado.paginator = this.paginatorPlazoFijoCerrado;
    this.dataSourceMovPlazoFijo.paginator = this.paginatorMovPlazoFijo;
    this.dataSourcePlazoFijoAbierto.paginator = this.paginatorPlazoFijoAbierto;

  }
  mostrarPlazoFijoFunction() {
    this.mostrarMovPlazoFijo = false;
    this.mostrarPlazoFijo = true;
  }
  mostrarMovPlazoFijoFunction() {
    this.mostrarMovPlazoFijo = true;
    this.mostrarPlazoFijo = false;
  }
  filtrarPlazoFijoCerrado(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourcePlazoFijoCerrado.filter = filtro.trim().toLowerCase();
    this.plazoFijoMsj = "Tabla filtrada por : " + filtro.trim().toLowerCase();
  }
  filtrarPlazoFijoAbierto(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourcePlazoFijoAbierto.filter = filtro.trim().toLowerCase();
    this.plazoFijoMsj = "Tabla filtrada por : " + filtro.trim().toLowerCase();
  }
  filtrarMovPlazoFijo(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourceMovPlazoFijo.filter = filtro.trim().toLowerCase();
    this.movPlazoFijoMsj = "Tabla filtrada por : " + filtro.trim().toLowerCase();
  }

}
