import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ClientePerfil } from 'src/app/class/cliente/cliente-perfil';
import { ClientePerfilDetallado } from 'src/app/class/cliente/cliente-perfil-detallado';
import { BilleteraDTO } from 'src/app/class/billetera/billetera-dto';
import { DepositoDTO } from 'src/app/class/billetera/deposito-dto';
import { InversionDTO } from 'src/app/class/billetera/inversion-dto';
import { CompraVentaDeMoneda } from 'src/app/class/billetera/compra-venta-de-moneda';
import { MovimientoDeInversionDTO } from 'src/app/class/billetera/movimiento-de-inversion-dto';
import { BalanceDTO } from 'src/app/class/balance/balance-dto';
import { DetalleMovimientoDTO } from 'src/app/class/detalleMobimiento/detalle-movimiento-dto';
import { MovimientoDTO } from 'src/app/class/movimiento/movimiento-dto';
import { CierreBalance } from 'src/app/class/coins/cierre-balance';
import { PlazoFijoDTO } from 'src/app/class/plazoFijo/plazoFijo-dto';
import { MovPlazoFijoDTO} from 'src/app/class/plazoFijo/movPlazoFijo-dto';
import { NuevoPlazoFijoDTO } from 'src/app/class/plazoFijo/nuevoPlazoFijo-dto';
import { HistorialAutomaticoDTO } from 'src/app/class/plazoFijo/historialAutomatico-dto';
import { ModMovPlazoFijoDTO } from 'src/app/class/plazoFijo/modMovPlazoFijo-dto';
import { environment } from 'src/environments/environment';
import { AgregarMovPasadoDTO } from 'src/app/class/plazoFijo/agregarMovPasado-dto';

const API_URL =  environment.backendURL+'/api/admin';


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient) { }

  getClientes(){
    return this.http.get<ClientePerfil[]>(API_URL + '/clientes/', { responseType: 'json' });
  }
  getClientePerfilByEmail(email: string) {
    return this.http.get<ClientePerfilDetallado>(API_URL + '/cliente/'+email, { responseType: 'json' });
  }
  updateClientePerfil(updateCliente: ClientePerfilDetallado): Observable<any> {
    return this.http.put<boolean>(API_URL + '/cliente/'+updateCliente.email,updateCliente, { responseType: 'json' });
  }
  getBilleteraDelClienteByEmail(email: string) {
    return this.http.get<BilleteraDTO[]>(API_URL + '/cliente/'+email+'/billetera', { responseType: 'json' });
  }
  getBalancesDelClienteByEmail(email: string) {
    return this.http.get<BalanceDTO[]>(API_URL + '/cliente/'+email+'/balance', { responseType: 'json' });
  }
  getMovimientosDelClienteByEmail(email: string) {
    return this.http.get<MovimientoDTO[]>(API_URL + '/cliente/'+email+'/movimientos', { responseType: 'json' });
  }
  getDetallesMovimientosDelClienteByEmail(email: string) {
    return this.http.get<DetalleMovimientoDTO[]>(API_URL + '/cliente/'+email+'/detalleMovimiento', { responseType: 'json' });
  }
  getDetallesMovimientosDelClienteByMovimientoIdAndEmail(movimientoId:number,email: string) {
    return this.http.get<DetalleMovimientoDTO[]>(API_URL + '/cliente/'+email+'/movimientos/'+movimientoId, { responseType: 'json' });
  }

  putDetallesMovimientosDepositoORetiroDelClienteByMovimientoIdAndEmail(movimientoId: number,cliente:string,depositoModificadoRequest:DepositoDTO) {
    return this.http.put(API_URL + '/cliente/'+cliente+'/movimientos/'+movimientoId+'/depositoORetiro',depositoModificadoRequest, { responseType: 'text' });
  }

  putDetallesMovimientosCompraOVentaDelClienteByMovimientoIdAndEmail(movimientoId: number,cliente:string,compraOVentaModificadaRequest:CompraVentaDeMoneda) {
    return this.http.put(API_URL + '/cliente/'+cliente+'/movimientos/'+movimientoId+'/compraOVenta',compraOVentaModificadaRequest, { responseType: 'text' });
  }


  depositarCliente(deposito: DepositoDTO): Observable<any> {
    return this.http.post<MovimientoDTO>(API_URL + '/depositar',deposito, { responseType: 'json' });
  }

  retirarCliente(retirarUsd: DepositoDTO): Observable<any> {
    return this.http.post<MovimientoDTO>(API_URL + '/retirar',retirarUsd, { responseType: 'json' });
  }

  invertirCliente(invertir: InversionDTO): Observable<any> {
    return this.http.post<MovimientoDTO>(API_URL + '/invertir',invertir, { responseType: 'json' });
  }
  cerrarBalanceDelCliente(cierreBalance: CierreBalance): Observable<any> {
    return this.http.post<any>(API_URL + '/cerrarBalance',cierreBalance, { responseType: 'json' });
  }

  comprarMonedaCliente(monedaAComprar: CompraVentaDeMoneda): Observable<any> {
    return this.http.post<MovimientoDeInversionDTO>(API_URL + '/comprarCripto',
    {cliente:monedaAComprar.cliente,
      inversion:[
        { moneda:monedaAComprar.moneda,
          cantidad:monedaAComprar.cantidad,
          montoUsd:monedaAComprar.montoUsd,
        }]}, { responseType: 'json' });
  }

  venderMonedaCliente(monedaAVender: CompraVentaDeMoneda): Observable<any> {
    return this.http.post<MovimientoDeInversionDTO>(API_URL + '/venderCripto',monedaAVender, { responseType: 'json' });
  }

  eliminarCliente(cliente: string): Observable<any> {
    return this.http.delete(API_URL + '/cliente/'+cliente, { responseType: 'text' });
  }

  // Plazo Fijo

  getPlazosFijosDelClienteByEmail(email: string) {
    return this.http.get<PlazoFijoDTO[]>(API_URL + '/plazo_fijo/'+email+'', { responseType: 'json' });
  }

  getMovimientosPlazoFijoDelClienteByEmail(email: string) {
    return this.http.get<MovPlazoFijoDTO[]>(API_URL + '/plazo_fijo/movimientos_plazo_fijo/'+email+'', { responseType: 'json' });
  }
  getPlazoFijoByMovPlazoFijoId(movPlazoFijoId: number, email: string) {
    return this.http.get<ModMovPlazoFijoDTO>(API_URL + '/plazo_fijo/'+ movPlazoFijoId +'/'+ email +'', { responseType: 'json' });
  }
  getMovPlazoFijoDepositoORetiroByMovId(movPlazoFijoId: number, email: string) {
    return this.http.get<number>(API_URL + '/plazo_fijo/modificarDepositoORetiro/'+ movPlazoFijoId +'/'+ email +'', { responseType: 'json' });
  }
  getHistorialAutomaticoDelClienteByEmail(email: string) {
    return this.http.get<HistorialAutomaticoDTO[]>(API_URL + '/plazo_fijo/historial_automatico/'+email+'', { responseType: 'json' });
  }
  depositarPlazoFijo(email: string, monto: number): Observable<any>  {
    return this.http.post(API_URL + '/plazo_fijo/deposito/'+email+'', monto, { responseType: 'text' });
  }
  retirarPlazoFijo(email: string, monto: number): Observable<any>  {
    return this.http.post(API_URL + '/plazo_fijo/retiro/'+email+'', monto, { responseType: 'text' });
  }
  crearPlazoFijo(email: string, nuevoPlazoFijoDTO: NuevoPlazoFijoDTO): Observable<any>  {
    return this.http.post(API_URL + '/plazo_fijo/'+email+'', nuevoPlazoFijoDTO, { responseType: 'text' });
  }
  cerrarPlazoFijo(email: string, plazoFijoId: number): Observable<any>  {
    return this.http.post(API_URL + '/plazo_fijo/cerrarPlazoFijo/'+email+'/'+plazoFijoId+'', null, { responseType: 'text' });
  }
  modificarMovInicioDePlazoFijo(modMovPlazoFijoDTO : ModMovPlazoFijoDTO, movPlazoFijoId: number, email: string): Observable<any>  {
    return this.http.put(API_URL + '/plazo_fijo/modificarMovInicioDePlazoFijo/'+movPlazoFijoId+'/'+ email +'',modMovPlazoFijoDTO, { responseType: 'text' });
  }
  modificarMovDepositoORetiroDePlazoFijo(monto: number, movPlazoFijoId: number, email: string): Observable<any>  {
    return this.http.put(API_URL + '/plazo_fijo/modificarMovDepositoORetiroDePlazoFijo/'+movPlazoFijoId+'/'+ email +'',monto, { responseType: 'text' });
  }
  cancelarOperacionCierrePlazoFijo(movPlazoFijoId: number, email: string): Observable<any>  {
    return this.http.put(API_URL + '/plazo_fijo/cancelarOperacionCierrePlazoFijo/'+movPlazoFijoId+'/'+ email +'', null, { responseType: 'text' });
  }
  cancelarMovimientoPlazoFijo(movPlazoFijoId: number, email: string): Observable<any>  {
    return this.http.put(API_URL + '/plazo_fijo/cancelarMovimientoPlazoFijo/'+movPlazoFijoId+'/'+ email +'', null, { responseType: 'text' });
  }
  agregarMovimientosPasados(email: string, agregarMovimientoPasadoDTO: AgregarMovPasadoDTO[],interesSeleccionado:number): Observable<any>  {
    return this.http.post(API_URL + '/plazo_fijo/agregarMovimientosPasados/'+email+'/'+interesSeleccionado, agregarMovimientoPasadoDTO, { responseType: 'text' });
  }
}
