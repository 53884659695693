<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>
      <h1 mat-dialog-title style="font-family: Ubuntu, sans-serif; text-align: center;">AGREGAR CLIENTE</h1>
    </mat-card-title>
    <mat-card-subtitle>
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>


    <mat-form-field>
      <input matInput [(ngModel)]="cliente.email" type="text" placeholder="Usuario">
    </mat-form-field>

    <mat-form-field>
      <input matInput [(ngModel)]="cliente.nombre" type="text" placeholder="Nombre">
    </mat-form-field>

    <mat-form-field>
      <input matInput [(ngModel)]="cliente.apellido" type="text" placeholder="Apellido">
    </mat-form-field>

    <mat-form-field>
      <input matInput [(ngModel)]="cliente.password" type="password" placeholder="Password">
    </mat-form-field>

    <mat-form-field>
      <input matInput [(ngModel)]="cliente.confirmPassword" type="password" placeholder="Confirm Password">
    </mat-form-field>



  </mat-card-content>
  <mat-card-footer>
    <mat-card-actions>
      <button class="default-btn"  style="margin-right: 10px" (click)="cancelar()">CANCELAR</button>
      <button class="default-btn"  [mat-dialog-close]="cliente" cdkFocusInitial>CONFIRMAR</button>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>