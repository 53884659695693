<div class="container">
  <div>

    <mat-card>
      <mat-card-header>
        <mat-card-title>
          <h1 mat-dialog-title>Balance desde el {{balanceSelected.fechaInicio | slice:2:10}} hasta el
            {{balanceSelected.fechaCierre| slice:2:10}}</h1>
        </mat-card-title>
        <mat-card-subtitle>

          <h4>Monto invertido: <span class="resaltado">${{balanceSelected.montoInvertido| number:'1.0-2':'es'}}
            </span><br>
            Capitalizacion bruta al momento del cierre: <span class="resaltado">${{balanceSelected.capitalizacionBruta|
              number:'1.0-2':'es'}} </span> <br>
            Comision: <span class="resaltado">${{balanceSelected.comision| number:'1.0-2':'es'}} </span> <br>
            Capitalizacion neta al momento del cierre: <span class="resaltado">${{balanceSelected.capitalizacionNeta|
              number:'1.0-2':'es'}} </span> <br>
            Balance: <span
              [ngStyle]="{'color': (balanceSelected.capitalizacionBruta>balanceSelected.montoInvertido || balanceSelected.capitalizacionBruta==null) ? 'rgb(27 182 56)' : 'rgb(241, 75, 75)'}">${{balanceSelected.balanceNeto|
              number:'1.0-2':'es'}} </span><br>
          </h4>
        </mat-card-subtitle>
      </mat-card-header>

      <mat-card-content>



      </mat-card-content>
    </mat-card>
  </div>

  <mat-form-field>

    <mat-label style="color: white;">Buscar</mat-label>

    <input matInput (keyup)="filtrar($event)" style="color:white;">

  </mat-form-field>


  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #tablaBalance matSort matSortActive="balanceId"
    matSortDirection="desc" matSortDisableClear>

    <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->



    <!-- Name Column -->

      <ng-container matColumnDef="fechaInicio">
        <th class="web-display" mat-header-cell *matHeaderCellDef> Fecha Inicio </th>
        <td class="web-display" mat-cell *matCellDef="let balance"> {{balance.fechaInicio | slice:2:10}} </td>
      </ng-container>


   
      <ng-container matColumnDef="inicio">
        <th class="phone-display" mat-header-cell *matHeaderCellDef> Inicio </th>
        <td class="phone-display" mat-cell *matCellDef="let balance"> {{balance.fechaInicio | slice:2:10}} </td>
      </ng-container>
 



    <!-- Name Column -->
    <ng-container matColumnDef="montoInvertido">
      <th mat-header-cell *matHeaderCellDef> Monto Invertido </th>
      <td mat-cell *matCellDef="let balance"> ${{balance.montoInvertido| number:'1.0-2':'es'}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="capitalizacionBruta">
      <th class="web-display" mat-header-cell *matHeaderCellDef> Capitalizacion Bruta </th>
      <td class="web-display" mat-cell *matCellDef="let balance"> ${{balance.capitalizacionBruta|
        number:'1.0-2':'es'}} </td>
    </ng-container>





    <!-- Name Column -->
    <ng-container matColumnDef="balanceNeto">
      <th mat-header-cell *matHeaderCellDef> Balance</th>
      <td mat-cell *matCellDef="let balance"> ${{balance.balanceNeto| number:'1.0-2':'es'}} </td>
    </ng-container>


    <!-- Name Column -->
    <ng-container matColumnDef="ver">
      <th mat-header-cell *matHeaderCellDef> Ver </th>
      <td mat-cell *matCellDef="let balance">
        <button mat-button color="primary" (click)="selectBalance(balance)">Ver</button>
      </td>
    </ng-container>



    <tr mat-header-row *matHeaderRowDef="displayedColumnsBalance"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsBalance;"></tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>



  <div mat-dialog-actions class="contenedor-boton">
    <button mat-button color="primary" (click)="relogearDatos()"><i class="fa fa-refresh"></i></button>
  </div>
</div>