<div   class="footer-area">
    <div class="container">
        <div class="row align-items-center justify-content-center">
            <div class="col-lg-5 col-md-5">
                
            </div>
            <div class="col-lg-2 col-md-2">
                
            </div>
            <div class="col-lg-5 col-md-5">
                <div class="single-footer">
                    <ul class="social-link">
                        <li>
                            <a href="https://discord.com/invite/tscz9EArHp" target="_blank">
                                <img src="assets/images/icon/discord.png" class="icon-social" alt="discord">
                                Discord
                            </a>
                        </li>
                        <li>
                            <a href="https://t.me/+W7HXmiq2zGZkZjkx" target="_blank">
                                <img src="assets/images/icon/telegram.png" class="icon-social" alt="telegram">
                                Telegram
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/hashratesolutions/" target="_blank">
                                <img src="assets/images/icon/icon-12.svg" alt="instagram">
                                Instagram
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
