import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MovimientoDTO } from 'src/app/class/movimiento/movimiento-dto';
import { EventBusService } from 'src/app/security/event-bus.service';
import { DetalleMovimientoDTO } from 'src/app/class/detalleMobimiento/detalle-movimiento-dto';
import { ClientService } from '../service/cliente.service';


@Component({
  selector: 'app-cliente-movimientos',
  templateUrl: './cliente-movimientos.component.html',
  styleUrls: ['./cliente-movimientos.component.css']
})
export class ClienteMovimientosComponent implements OnInit {

  movimientoMsj= "Estos son tus movimientos.";
  displayedColumnsMovimiento: string[] = [ 'fecha','transaccion','ver'];
  movimiento:MovimientoDTO[]=[];
  detalleMovimientoSelected: DetalleMovimientoDTO[] = [new DetalleMovimientoDTO(0,0,'','','',0,0,0),new DetalleMovimientoDTO(0,0,'','','',0,0,0)];
  detalleMovimientoClear: DetalleMovimientoDTO=new DetalleMovimientoDTO(0,0,'','','',0,0,0);
  movimientoClear: MovimientoDTO=new MovimientoDTO(0,0,'','',0);
  isModificarDetalle1:boolean =true;
  isModificarDetalle1AndIsCompraOventa:boolean =false;
  isNotModificarDetalle1AndIsCompraOventa:boolean =false;

  movimientoSelected:MovimientoDTO=new MovimientoDTO(0,0,'','',0);



  @ViewChild(MatTable) tablaMovimiento!: MatTable<MovimientoDTO>;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  dataSource!: MatTableDataSource<MovimientoDTO>;

  constructor(
    private clientService: ClientService, private eventBusService: EventBusService) {}

  ngOnInit() {
    this.relogearDatos();
    this.reloadTable();
    registerLocaleData( es );
  }

  relogearDatos(){
    this.clientService.getMovimientosDelCliente().subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.movimientoMsj = JSON.parse(err.error).message;},    // errorHandler
        next: (data) => {
          this.movimiento = data;
          this.reloadTable();     // nextHandler
        }
      }
    );
    registerLocaleData( es );
    this.reloadTable();
  }

  getDetallesMovimientos(movimiento:MovimientoDTO){
    this.clientService.getDetallesMovimientosDelCliente(movimiento.movimientoId).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.movimientoMsj = JSON.parse(err.error).message;},    // errorHandler
        next: (data) => {
          this.movimientoSelected=movimiento;
          this.movimientoMsj ='Fecha-'+movimiento.fecha+' Balance-'+movimiento.balanceId+' TRANSACCION-'+movimiento.transaccion;
          this.detalleMovimientoSelected[0] =this.detalleMovimientoClear;
          this.detalleMovimientoSelected[1] =this.detalleMovimientoClear;
          if(data[0]!=null){
            this.detalleMovimientoSelected[0] =data[0];
          }
          if(data[1]!=null){
            this.detalleMovimientoSelected[1] =data[1];
          }
          this.reloadTable();;     // nextHandler
        }
      }
    );
    registerLocaleData( es );
    this.reloadTable();
  }
  reloadTable(){
    this.dataSource = new MatTableDataSource<MovimientoDTO>(this.movimiento);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }
  filtrar(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filtro.trim().toLowerCase();
    this.movimientoMsj = "Tabla filtrada por : "+ filtro.trim().toLowerCase();
  }
}
