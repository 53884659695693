export class DepositoDTO {
  email:string;
  monto:number;


  constructor( email: string,  monto: number) {
    this.email =email;
    this.monto = monto;
  }
}
