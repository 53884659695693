<div class="container">
  <div class="menuPlazoFijo">
    <button mat-button color="primary" (click)="mostrarPlazoFijoFunction()">Mostrar Plazos Fijos</button>
    <button mat-button color="primary" (click)="mostrarMovPlazoFijoFunction()">Mostrar Movimientos</button>
  </div>

  <div class="contenedor-plazoFijo" *ngIf="mostrarPlazoFijo && plazoFijoAbierto.length > 0">
    <div class="contenedor-cuentaCorriente">
      <h3>Monto invertido: </h3>
      <h1>${{this.montoInvertido}}</h1>
    </div>

    <h1 style="text-align: center;">Plazo Fijo Abierto</h1>
    <!-- <mat-form-field>
    <mat-label>Buscar</mat-label>
    <input matInput (keyup)="filtrarPlazoFijoAbierto($event)" placeholder="buscar">
   </mat-form-field> -->
    <div class="plazoFijo-tabla">
      <table mat-table [dataSource]="dataSourcePlazoFijoAbierto" class="mat-elevation-z8" #tablaPlazoFijoAbierto>

        <ng-container matColumnDef="fechaDeInicio">
          <th mat-header-cell *matHeaderCellDef> Fecha de inicio </th>
          <td mat-cell *matCellDef="let plazoFijoAbierto">
            <span class="mobile-label">Fecha de inicio:</span>
            {{plazoFijoAbierto.fechaDeInicio}}
          </td>
        </ng-container>

        <ng-container matColumnDef="montoInvertido">
          <th mat-header-cell *matHeaderCellDef> Monto invertido </th>
          <td mat-cell *matCellDef="let plazoFijoAbierto">
            <span class="mobile-label">Monto invertido:</span>
            ${{plazoFijoAbierto.montoInvertido}}
          </td>
        </ng-container>

        <ng-container matColumnDef="mesesCumplidos">
          <th mat-header-cell *matHeaderCellDef> Meses Cumplidos </th>
          <td mat-cell *matCellDef="let plazoFijoAbierto">
            <span class="mobile-label">Meses Cumplidos:</span>
            {{plazoFijoAbierto.mesesCumplidos}}
          </td>
        </ng-container>


        <ng-container matColumnDef="interesAnual">
          <th mat-header-cell *matHeaderCellDef> Interés</th>
          <td mat-cell *matCellDef="let plazoFijoAbierto">
            <span class="mobile-label">Interés:</span>
            {{plazoFijoAbierto.interesAnual}}%
          </td>
        </ng-container>

        <ng-container matColumnDef="montoAcumuladoMensual">
          <th mat-header-cell *matHeaderCellDef> Monto acumulado mensual</th>
          <td mat-cell *matCellDef="let plazoFijoAbierto">
            <span class="mobile-label">Monto acumulado mensual:</span>
            ${{plazoFijoAbierto.montoAcumuladoMensual}}
          </td>
        </ng-container>

        <ng-container matColumnDef="gananciaNeta">
          <th mat-header-cell *matHeaderCellDef> Ganancia neta</th>
          <td mat-cell *matCellDef="let plazoFijoAbierto">
            <span class="mobile-label">Ganancia neta:</span>
            ${{plazoFijoAbierto.gananciaNeta}}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsPlazoFijoAbierto"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumnsPlazoFijoAbierto;"></tr>
      </table>
    </div>

    <!-- <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator> -->


  </div>












  <div class="contenedor-plazoFijo" [hidden]="!mostrarPlazoFijo || plazoFijoCerrado.length == 0">
    <h1 style="text-align: center;">Plazos Fijos Cerrados</h1>
    <mat-form-field>
      <mat-label style="color: white;">Buscar</mat-label>
      <input matInput (keyup)="filtrarPlazoFijoCerrado($event)">
    </mat-form-field>
    <table mat-table [dataSource]="dataSourcePlazoFijoCerrado" class="mat-elevation-z8" #tablaPlazoFijoCerrado matSort
      #sortPlazoFijoCerrado="matSort" matSortActive="fechaDeInicio" matSortDirection="desc" matSortDisableClear>

      <!--- Note that these columns can be defined in any order.
            The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="fechaDeInicio">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de inicio </th>
        <td mat-cell *matCellDef="let plazoFijoCerrado">
          <span class="mobile-label">Fecha de inicio:</span>
          {{plazoFijoCerrado.fechaDeInicio}}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="mesesCumplidos">
        <th mat-header-cell *matHeaderCellDef> Meses Cumplidos </th>
        <td mat-cell *matCellDef="let plazoFijoCerrado">
          <span class="mobile-label">Meses Cumplidos:</span>
          {{plazoFijoCerrado.mesesCumplidos}}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="fechaDeCierre">
        <th mat-header-cell *matHeaderCellDef> Fecha de cierre </th>
        <td mat-cell *matCellDef="let plazoFijoCerrado">
          <span class="mobile-label">Fecha de cierre:</span>
          {{plazoFijoCerrado.fechaDeCierre}}
        </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="interesAnual">
        <th mat-header-cell *matHeaderCellDef> Interés anual</th>
        <td mat-cell *matCellDef="let plazoFijoCerrado">
          <span class="mobile-label">Interés anual:</span>
          {{plazoFijoCerrado.interesAnual}}%
        </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="gananciaNeta">
        <th mat-header-cell *matHeaderCellDef> Ganancia neta </th>
        <td mat-cell *matCellDef="let plazoFijoCerrado">
          <span class="mobile-label">Ganancia neta:</span>
          ${{plazoFijoCerrado.gananciaNeta}}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsPlazoFijoCerrado"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsPlazoFijoCerrado;"></tr>
    </table>
    <mat-paginator matPaginator #paginatorPlazoFijoCerrado='matPaginator' [pageSizeOptions]="[5, 10, 15]"
      showFirstLastButtons></mat-paginator>
  </div>


















  <!-- <div *ngIf="mostrarMovPlazoFijo"> -->
  <div [hidden]="!mostrarMovPlazoFijo">
    <mat-form-field>
      <mat-label style="color: white;">Buscar</mat-label>
      <input matInput (keyup)="filtrarMovPlazoFijo($event)">
    </mat-form-field>
    <table mat-table [dataSource]="dataSourceMovPlazoFijo" class="mat-elevation-z8" #tablaMovPlazoFijo matSort
      #sortMovPlazoFijo="matSort" matSortActive="fechaDeCreacion" matSortDirection="desc" matSortDisableClear>

      <!--- Note that these columns can be defined in any order.
              The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="fechaDeCreacion">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha </th>
        <td mat-cell *matCellDef="let movPlazoFijo">
          <span class="mobile-label">Fecha:</span>
          {{movPlazoFijo.fechaDeCreacion}}
        </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="monto">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Monto </th>
        <td mat-cell *matCellDef="let movPlazoFijo">
          <span class="mobile-label">Monto:</span>
          {{movPlazoFijo.monto}}
        </td>
      </ng-container>
      <ng-container matColumnDef="operacion">
        <th mat-header-cell *matHeaderCellDef> Operación </th>
        <td mat-cell *matCellDef="let movPlazoFijo">
          <span class="mobile-label">Operación:</span>
          {{movPlazoFijo.operacion}}
        </td>
      </ng-container>
      <ng-container matColumnDef="transaccion">
        <th mat-header-cell *matHeaderCellDef> Transacción </th>
        <td mat-cell *matCellDef="let movPlazoFijo">
          <span class="mobile-label">Transacción:</span>
           {{movPlazoFijo.transaccion}} 
          </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumnsMovPlazoFijo"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsMovPlazoFijo;"></tr>
    </table>

    <mat-paginator matPaginator #paginatorMovPlazoFijo='matPaginator' [pageSizeOptions]="[5, 10, 15]"
      showFirstLastButtons>
    </mat-paginator>
  </div>
</div>