<div class="col-md-12 contenedor">
  <div class="card card-container">
    <img
      id="profile-img"
      src="/assets/img/icono.png"
      class="profile-img-card"
    />
    <form
      *ngIf="!isSuccessful"
      name="form"
      (ngSubmit)="f.form.valid && onSubmit()"
      #f="ngForm"
      novalidate
    >
      <div class="form-group">
        <label for="email">Email</label>
        <input
          type="email"
          class="form-control"
          name="email"
          [(ngModel)]="form.email"
          required
          email
          #email="ngModel"
        />
        <div class="alert-danger" *ngIf="email.errors && f.submitted">
          <div *ngIf="form.controls.email.errors.required">Este campo es requerido</div>
          <div *ngIf="form.controls.email.errors.email">
            Tiene que ser un formato email valido
          </div>
        </div>
      </div>

      <div class="form-group">
        <label for="password">Clave</label>
        <input
          type="password"
          class="form-control"
          name="password"
          [(ngModel)]="form.password"
          required
          minlength="8"
          #password="ngModel"
        />
        <div class="alert-danger" *ngIf="password.errors && f.submitted">
          <div *ngIf="form.controls.password.errors.required">Este campo es requerido</div>
          <div *ngIf="form.controls.password.errors.minlength">
           Tiene que tener como minimo 8 caracteres
          </div>
        </div>
      </div>


      <div class="form-group">
        <label>Confirmacion de clave</label>
        <input
          type="password"
          class="form-control"
          name="confirmPassword"
          [(ngModel)]="form.confirmPassword"
          required
          minlength="8"
          #confirmPassword="ngModel"
        />
        <div class="alert-danger"
          *ngIf="confirmPassword.errors && f.submitted"
          class="invalid-feedback"
        >
          <div *ngIf="form.controls.confirmPassword.errors.required">
            Este campo es requerido
          </div>
          <div *ngIf="form.controls.confirmPassword.errors.matching">
           Las contraseñas no machean
          </div>
        </div>
      </div>




      <div class="form-group buttons">
        <button class="default-btn" style="margin-bottom: 12px;" color="primary">
          REGISTRAR CLIENTE
        </button>
        <button class="default-btn" color="primary" (click)="registrarAdmin()">
          REGISTRAR ADMIN
        </button>
      </div>

      <div class="alert alert-warning" *ngIf="f.submitted && isSignUpFailed">
        La registración fallo!<br />{{ errorMessage }}
      </div>
    </form>

    <div class="alert alert-success" *ngIf="isSuccessful">
      Su registración fue exitosa!
    </div>
  </div>
</div>
