export class BalanceDTO {
  balanceId:number;
  fechaInicio:string;
  fechaCierre:string;
  montoInvertido:number;
  capitalizacionBruta:number;
  comision:number;
  capitalizacionNeta:number;
  balanceNeto:number;
  cliente:string;


  constructor(balanceId:number, fechaInicio: string,  fechaCierre: string,
    montoInvertido: number,  capitalizacionBruta: number,
    comision: number,  capitalizacionNeta: number,
    balanceNeto: number,  cliente: string) {
      this.balanceId =balanceId;
      this.fechaInicio =fechaInicio;
      this.fechaCierre = fechaCierre;
      this.montoInvertido =montoInvertido;
      this.capitalizacionBruta = capitalizacionBruta;
      this.comision =comision;
      this.capitalizacionNeta = capitalizacionNeta;
      this.balanceNeto =balanceNeto;
      this.cliente = cliente;
  }
}
