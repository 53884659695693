import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { EventBusService } from 'src/app/security/event-bus.service';
import { AdminService } from '../../service/admin.service';
import { BalanceDTO } from 'src/app/class/balance/balance-dto';
import { EventData } from 'src/app/security/event.class';
import { BilleteraDTO } from 'src/app/class/billetera/billetera-dto';
import { CerrarBalanceComponent } from '../cerrar-balance/cerrar-balance.component';



@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.css']
})
export class BalanceComponent implements OnInit {
  balanceMsj= "Estos son los Balances del cliente.";
  displayedColumnsBalance: string[] = [ 'balanceId','fechaInicio','fechaCierre','montoInvertido','capitalizacionBruta','comision','capitalizacionNeta','balanceNeto'];

  @ViewChild(MatTable) tablaBalance!: MatTable<BalanceDTO>;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  dataSource!: MatTableDataSource<BalanceDTO>;
  content?: string;

  constructor(
    public dialogRef: MatDialogRef<BalanceComponent>,
    @ Inject(MAT_DIALOG_DATA) public data:{balance: BalanceDTO[],cliente:''}
    ,public dialog: MatDialog,
    private adminService: AdminService, private eventBusService: EventBusService) {}

  ngOnInit() {
    this.reloadTable();
    registerLocaleData( es );
  }

  cancelar() {
    this.dialogRef.close();
  }
  relogearDatos(){
    this.adminService.getBalancesDelClienteByEmail(this.data.cliente).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.balanceMsj = JSON.parse(err.error).message;},    // errorHandler
        next: (data) => {
          this.data.balance = data;
          this.reloadTable();     // nextHandler
        }
      }
    );
    registerLocaleData( es );
    this.reloadTable();
  }

  reloadTable(){
    this.dataSource = new MatTableDataSource<BalanceDTO>(this.data.balance);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }
  filtrar(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filtro.trim().toLowerCase();
    this.balanceMsj = "Tabla filtrada por : "+ filtro.trim().toLowerCase();
  }





  cerrarBalance() {
    this.adminService.getBilleteraDelClienteByEmail(this.data.cliente).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.content = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (data) => {
          this.content = "Se abrio el dialogo de la billetera de "+this.data.cliente;
          this.abrirDialogocerrarBalanceDelCliente(data,this.data.cliente);
          this.reloadTable();},     // nextHandler
    }

    );
  }

  abrirDialogocerrarBalanceDelCliente(billetera: BilleteraDTO[],cliente:string) {

    const dialogo = this.dialog.open(CerrarBalanceComponent, {
      data: {billetera
        , cliente
      }
    });
    dialogo.afterClosed().subscribe(() => {
      this.reloadTable();
    });
  }
}
