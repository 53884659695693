export class MonedasPrecio {
  moneda:string;
  precio:number;


  constructor( moneda: string,  precio: number) {
    this.moneda =moneda;
    this.precio = precio;
  }
}
