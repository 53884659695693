<div class="container-fluid">
    <nav class="navbar navbar-expand-lg navbar-light" style="padding: 20px 30px">
        
            <a class="navbar-brand" routerLink="/home">
                <img src="../../../assets/img/logo-w-header.png" style="width: 180px;" alt="logo">
            </a>
            <button class="navbar-toggler d-lg-none" type="button" data-toggle="collapse"
                data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false"
                aria-label="Toggle navigation">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul class="navbar-nav" routerLinkActive="active">
                <li class="nav-item" *ngIf="showAdminBoard">
                    <a href="/admin" class="nav-link" routerLink="admin">Admin</a>
                </li>
                <li class="nav-item" *ngIf="showClientBoard">
                    <a href="/cliente" class="nav-link" [style.color]="billeteraSelected ? '#FA26CA' : ''"
                        (click)="selectBilleteraFunction()" routerLink="cliente">Billetera</a>
                </li>
                <li class="nav-item" *ngIf="showClientBoard">
                    <a href="/clienteMovimiento" class="nav-link"
                    [style.color]="movimientoSelected ? '#FA26CA' : ''"
                        (click)="selectMovimientoFunction()" routerLink="clienteMovimiento">Movimiento</a>
                </li>
                <li class="nav-item" *ngIf="showClientBoard">
                    <a href="/clienteBalance" class="nav-link"
                    [style.color]="balanceSelected ? '#FA26CA' : ''" (click)="selectBalanceFunction()"
                        routerLink="clienteBalance">Balance</a>
                </li>
                <li class="nav-item" *ngIf="showClientBoard">
                    <a href="/clientePlazoFijo" class="nav-link"
                    [style.color]="plazoFijoSelected ? '#FA26CA' : ''"
                        (click)="selectPlazoFijoFunction()" routerLink="clientePlazoFijo">Plazo Fijo</a>
                </li>
            </ul>

            <div class="others-option d-flex align-items-center" *ngIf="!isLoggedIn">
                <div class="option-item">
                    <a routerLink="/login" class="default-btn">INGRESAR</a>
                </div>
            </div>

            <div class="others-option d-flex align-items-center" *ngIf="isLoggedIn">
                <div class="option-item">
                    <a href="/home" (click)="logout()" class="default-btn">SALIR</a>
                </div>
            </div>
        </div>
    </nav>
</div>
