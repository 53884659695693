import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, HostListener } from '@angular/core';
import { PublicService } from '../service/public.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  content?: string;

  innerWidth: any;

  constructor(private publicService: PublicService, private elementRef: ElementRef) { }
  ngAfterViewInit(): void {
  }
  ngOnDestroy(): void {
    this.elementRef.nativeElement.ownerDocument
      .body.style.backgroundImage = 'none';
    this.elementRef.nativeElement.ownerDocument
      .body.style.backgroundColor = 'white';

  }

  ngOnInit(): void {
    this.innerWidth = window.innerWidth;
    this.publicService.getPublicContent().subscribe(
      {
        complete: () => { }, // completeHandler
        error: (err) => {
          this.content = JSON.parse(err.error).message;
        },    // errorHandler
        next: (data) => {
          this.content = data;     // nextHandler
        }
      }
    );
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.innerWidth;
  }

}
