import { Component, OnInit, Inject } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EventBusService } from 'src/app/security/event-bus.service';
import { AdminService } from '../../service/admin.service';



@Component({
  selector: 'app-nuevo-cliente-dialog',
  templateUrl: './nuevo-cliente-dialog.component.html',
  styleUrls: ['./nuevo-cliente-dialog.component.css']
})
export class NuevoClienteDialogComponent implements OnInit {
  content?: string;


  constructor(
    public dialogRef: MatDialogRef<NuevoClienteDialogComponent>,
    @ Inject(MAT_DIALOG_DATA) public cliente: {
      email:'',
      nombre:'',
      apellido:'',
      password:'',
      confirmPassword:''
    },
    private adminService: AdminService, private eventBusService: EventBusService) {}

  ngOnInit() {
  }

  cancelar() {
    this.dialogRef.close();
  }
}
