export class AgregarMovPasadoDTO {

    monto: number;
    fechaDeOperacion: string;
    operacion: string;

    constructor(monto: number, fechaDeOperacion: string, operacion: string) {
        this.monto = monto;
        this.fechaDeOperacion = fechaDeOperacion;
        this.operacion = operacion;
    }
}
