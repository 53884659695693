import { formatDate, registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MovPlazoFijoDTO } from 'src/app/class/plazoFijo/movPlazoFijo-dto';
import { NuevoPlazoFijoDTO } from 'src/app/class/plazoFijo/nuevoPlazoFijo-dto';
import { PlazoFijoDTO } from 'src/app/class/plazoFijo/plazoFijo-dto';
import { EventBusService } from 'src/app/security/event-bus.service';
import { AdminService } from '../../service/admin.service';
import { HistorialAutomaticoDTO } from 'src/app/class/plazoFijo/historialAutomatico-dto';
import { ModMovPlazoFijoDTO } from 'src/app/class/plazoFijo/modMovPlazoFijo-dto';
import { AgregarMovPasadoDTO } from 'src/app/class/plazoFijo/agregarMovPasado-dto';

@Component({
  selector: 'app-plazo-fijo',
  templateUrl: './plazo-fijo.component.html',
  styleUrls: ['./plazo-fijo.component.css']
})
export class PlazoFijoComponent implements OnInit {
  plazoFijoMsj = "Estos son los Plazos fijos del cliente.";
  movPlazoFijoMsj = "Estos son los movimientos del plazo fijo del cliente.";
  plazoFijoCerrado: PlazoFijoDTO[] = [];
  plazoFijoAbierto: PlazoFijoDTO[] = [];
  nuevoPlazoFijoDTO: NuevoPlazoFijoDTO = new NuevoPlazoFijoDTO(0, 0, "");
  modMovPlazoFijoDTO: ModMovPlazoFijoDTO = new ModMovPlazoFijoDTO(0, "", 0);
  agregarMovPasadoDTO: AgregarMovPasadoDTO = new AgregarMovPasadoDTO(0, "", "");
  movPlazoFijo: MovPlazoFijoDTO[] = [];
  historialAutomatico: HistorialAutomaticoDTO[] = [];
  movimientoPasadoArray: AgregarMovPasadoDTO[]=[];
  formatDate: Date = new Date();
  minDate = new Date();
  dateString: string = "";
  interesAnualString: string = "";
  agregarCampo: number = 0;

  idSeleccionado: number = 0;
  montoSeleccionado: number = 0;
  interesSeleccionado: string = "";
  fechaSeleccionada: string = "";
  operacionSeleccionada: string = "";


  mostrarMovPlazoFijo = false;
  mostrarPlazoFijo = true;
  mostrarDepositoORetiro = false;
  mostrarNuevoPlazoFijo = false;
  mostrarHistorialAutomatico = false;
  mostrarAgregarMovimiento = false;
  modificarMovimientoPlazoFijo = false;
  modificarMovimientoPlazoFijoDepositoORetiro = false;

  interesAnual: number | undefined;
  fechaDeInicio: string | undefined;

  montoRetiroDeposito: number = 0;

  displayedColumnsPlazoFijoCerrado: string[] = ['fechaDeInicio', 'fechaDeCierre', 'mesesCumplidos', 'interesAnual', 'gananciaNeta'];
  displayedColumnsPlazoFijoAbierto: string[] = ['fechaDeInicio', 'montoInvertido', 'mesesCumplidos', 'interesAnual', 'montoAcumuladoMensual', 'gananciaNeta', 'cancelacion'];
  displayedColumnsMovPlazoFijo: string[] = ['fechaDeCreacion', 'operacion', 'monto', 'transaccion', 'acciones'];
  displayedColumnsHistorialAutomatico: string[] = ['fechaDeCreacion', 'operacion', 'monto'];

  @ViewChild(MatTable) tablaPlazoFijoCerrado!: MatTable<PlazoFijoDTO>;
  @ViewChild(MatTable) tablaPlazoFijoAbierto!: MatTable<PlazoFijoDTO>;
  @ViewChild(MatTable) tablaMovPlazoFijo!: MatTable<MovPlazoFijoDTO>;
  @ViewChild(MatTable) tablaHistorialAutomatico!: MatTable<HistorialAutomaticoDTO>;
  @ViewChild('paginatorPlazoFijoCerrado') paginatorPlazoFijoCerrado!: MatPaginator;
  @ViewChild('paginatorPlazoFijoAbierto') paginatorPlazoFijoAbierto!: MatPaginator;
  @ViewChild('paginatorMovPlazoFijo') paginatorMovPlazoFijo!: MatPaginator;
  @ViewChild('paginatorHistorialAutomatico') paginatorHistorialAutomatico!: MatPaginator;
  @ViewChild('sortPlazoFijoAbierto') sortPlazoFijoCerrado!: MatSort;
  @ViewChild('sortPlazoFijoAbierto') sortPlazoFijoAbierto!: MatSort;
  @ViewChild('sortMovPlazoFijo') sortMovPlazoFijo!: MatSort;
  @ViewChild('sortHistorialAutomatico') sortHistorialAutomatico!: MatSort;

  dataSourcePlazoFijoCerrado!: MatTableDataSource<PlazoFijoDTO>;
  dataSourceMovPlazoFijo!: MatTableDataSource<MovPlazoFijoDTO>;
  dataSourcePlazoFijoAbierto!: MatTableDataSource<PlazoFijoDTO>;
  dataSourceHistorialAutomatico!: MatTableDataSource<HistorialAutomaticoDTO>;
  content?: string;

  constructor(public dialogRef: MatDialogRef<PlazoFijoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { cliente: string }
    , public dialog: MatDialog,
    private adminService: AdminService, private eventBusService: EventBusService) { }

  ngOnInit(): void {
    this.adminService.getPlazosFijosDelClienteByEmail(this.data.cliente).subscribe(
      {
        complete: () => { }, // completeHandler
        error: (err) => {
          alert(JSON.parse(err.error).message);
          this.plazoFijoMsj = JSON.parse(err.error).message;
        },    // errorHandler
        next: (data) => {
          data.forEach((value) => {
            if (value.fechaDeCierre == null) {
              this.plazoFijoAbierto.push(value);
            } else {
              this.plazoFijoCerrado.push(value)
            }
          })
          this.reloadTable();     // nextHandler
        }
      }
    );
    this.adminService.getMovimientosPlazoFijoDelClienteByEmail(this.data.cliente).subscribe(
      {
        complete: () => { }, // completeHandler
        error: (err) => {
          alert(JSON.parse(err.error).message);
          this.movPlazoFijoMsj = JSON.parse(err.error).message;
        },    // errorHandler
        next: (data) => {
          this.movPlazoFijo = data;
          this.reloadTable();     // nextHandler
        }
      }
    );
    this.adminService.getHistorialAutomaticoDelClienteByEmail(this.data.cliente).subscribe(
      {
        complete: () => { }, // completeHandler
        error: (err) => {
          alert(JSON.parse(err.error).message);
          this.movPlazoFijoMsj = JSON.parse(err.error).message;
        },    // errorHandler
        next: (data) => {
          this.historialAutomatico = data;
          this.reloadTable();     // nextHandler
        }
      }
    );
    this.movimientoPasadoArray.push(this.agregarMovPasadoDTO);
  }

  cancelar() {
    this.dialogRef.close();
  }

  relogearDatos() {
    this.adminService.getPlazosFijosDelClienteByEmail(this.data.cliente).subscribe(
      {
        complete: () => { }, // completeHandler
        error: (err) => {
          alert(JSON.parse(err.error).message);
          this.plazoFijoMsj = JSON.parse(err.error).message;
        },    // errorHandler
        next: (data) => {
          this.plazoFijoCerrado = [];
          this.plazoFijoAbierto = [];
          data.forEach((value) => {
            if (value.fechaDeCierre == null) {
              this.plazoFijoAbierto.push(value);
            } else {
              this.plazoFijoCerrado.push(value)
            }
          })
          this.reloadTable();     // nextHandler
        }
      }
    );

    this.adminService.getMovimientosPlazoFijoDelClienteByEmail(this.data.cliente).subscribe(
      {
        complete: () => { }, // completeHandler
        error: (err) => {
          alert(JSON.parse(err.error).message);
          this.plazoFijoMsj = JSON.parse(err.error).message;
        },    // errorHandler
        next: (data) => {
          this.movPlazoFijo = data;
          this.reloadTable();     // nextHandler
        }
      }
    );
    this.adminService.getHistorialAutomaticoDelClienteByEmail(this.data.cliente).subscribe(
      {
        complete: () => { }, // completeHandler
        error: (err) => {
          alert(JSON.parse(err.error).message);
          this.plazoFijoMsj = JSON.parse(err.error).message;
        },    // errorHandler
        next: (data) => {
          this.historialAutomatico = data;
          this.reloadTable();     // nextHandler
        }
      }
    );
    registerLocaleData(es);
    this.reloadTable();
  }

  reloadTable() {
    this.dataSourcePlazoFijoCerrado = new MatTableDataSource<PlazoFijoDTO>(this.plazoFijoCerrado);
    this.dataSourcePlazoFijoAbierto = new MatTableDataSource<PlazoFijoDTO>(this.plazoFijoAbierto);
    this.dataSourceMovPlazoFijo = new MatTableDataSource<MovPlazoFijoDTO>(this.movPlazoFijo);
    this.dataSourceHistorialAutomatico = new MatTableDataSource<HistorialAutomaticoDTO>(this.historialAutomatico);
    this.dataSourcePlazoFijoCerrado.sort = this.sortPlazoFijoCerrado;
    this.dataSourceMovPlazoFijo.sort = this.sortMovPlazoFijo;
    this.dataSourcePlazoFijoAbierto.sort = this.sortPlazoFijoAbierto;
    this.dataSourceHistorialAutomatico.sort = this.sortHistorialAutomatico;
    this.dataSourcePlazoFijoCerrado.paginator = this.paginatorPlazoFijoCerrado;
    this.dataSourceMovPlazoFijo.paginator = this.paginatorMovPlazoFijo;
    this.dataSourcePlazoFijoAbierto.paginator = this.paginatorPlazoFijoAbierto;
    this.dataSourceHistorialAutomatico.paginator = this.paginatorHistorialAutomatico;
  }

  filtrarMovimientos(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourceMovPlazoFijo.filter = filtro.trim().toLowerCase();
  }
  filtrarPlazosFijosCerrados(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourcePlazoFijoCerrado.filter = filtro.trim().toLowerCase();
  }
  filtrarPlazosFijosAbiertos(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourcePlazoFijoAbierto.filter = filtro.trim().toLowerCase();
  }
  filtrarHistorialAutomatico(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSourceHistorialAutomatico.filter = filtro.trim().toLowerCase();
  }

  mostrarPlazoFijoFunction() {
    this.mostrarMovPlazoFijo = false;
    this.mostrarPlazoFijo = true;
    this.mostrarDepositoORetiro = false;
    this.mostrarNuevoPlazoFijo = false;
    this.mostrarHistorialAutomatico = false;
    this.modificarMovimientoPlazoFijo = false;
    this.modificarMovimientoPlazoFijoDepositoORetiro = false;
    this.mostrarAgregarMovimiento = false;

    this.idSeleccionado = 0;
    this.montoSeleccionado = 0;
    this.interesSeleccionado = "";
    this.fechaSeleccionada = "";
    this.operacionSeleccionada = "";
  }
  mostrarMovPlazoFijoFunction() {
    this.mostrarMovPlazoFijo = true;
    this.mostrarPlazoFijo = false;
    this.mostrarDepositoORetiro = false;
    this.mostrarNuevoPlazoFijo = false;
    this.mostrarHistorialAutomatico = false;
    this.modificarMovimientoPlazoFijo = false;
    this.modificarMovimientoPlazoFijoDepositoORetiro = false;
    this.mostrarAgregarMovimiento = false;

    this.idSeleccionado = 0;
    this.montoSeleccionado = 0;
    this.interesSeleccionado = "";
    this.fechaSeleccionada = "";
    this.operacionSeleccionada = "";
  }
  mostrarHistorialAutomaticoFunction() {
    this.mostrarMovPlazoFijo = false;
    this.mostrarPlazoFijo = false;
    this.mostrarDepositoORetiro = false;
    this.mostrarNuevoPlazoFijo = false;
    this.mostrarHistorialAutomatico = true;
    this.modificarMovimientoPlazoFijo = false;
    this.modificarMovimientoPlazoFijoDepositoORetiro = false;
    this.mostrarAgregarMovimiento = false;

    this.idSeleccionado = 0;
    this.montoSeleccionado = 0;
    this.interesSeleccionado = "";
    this.fechaSeleccionada = "";
    this.operacionSeleccionada = "";
  }
  mostrarAgregarMovimientoFunction() {
    this.mostrarMovPlazoFijo = false;
    this.mostrarPlazoFijo = false;
    this.mostrarDepositoORetiro = false;
    this.mostrarNuevoPlazoFijo = false;
    this.mostrarHistorialAutomatico = false;
    this.modificarMovimientoPlazoFijo = false;
    this.modificarMovimientoPlazoFijoDepositoORetiro = false;
    this.mostrarAgregarMovimiento = true;

    this.idSeleccionado = 0;
    this.montoSeleccionado = 0;
    this.interesSeleccionado = "";
    this.fechaSeleccionada = "";
    this.operacionSeleccionada = "";
  }
  mostrarDepositoORetiroFunction() {
    if (this.mostrarDepositoORetiro) {
      this.mostrarDepositoORetiro = false;
    } else {
      this.mostrarNuevoPlazoFijo = false;
      this.mostrarDepositoORetiro = true;

    }
  }
  mostrarNuevoPlazoFijoFunction() {
    if (this.mostrarNuevoPlazoFijo) {
      this.mostrarNuevoPlazoFijo = false;
    } else {
      this.mostrarDepositoORetiro = false;
      this.mostrarNuevoPlazoFijo = true;
    }
  }

  crearPlazoFijo() {
    this.formatearFecha();
    if (this.interesAnualString == "") {
      alert("Verifica los datos")
    } else {
      this.transformarStringANumber();
      this.nuevoPlazoFijoDTO.fechaDeInicio = this.dateString;
      if (this.nuevoPlazoFijoDTO.fechaDeInicio == "" || this.nuevoPlazoFijoDTO.montoInvertido <= 0
        || this.nuevoPlazoFijoDTO.interesAnual <= 0) {
        alert("Verifique los campos.")
      } else if (confirm("¿Estás seguro de crear un plazo fijo a nombre de " + this.data.cliente + "?") == true) {
        this.adminService.crearPlazoFijo(this.data.cliente, this.nuevoPlazoFijoDTO).subscribe(
          {
            complete: () => { }, // completeHandler
            error: (err) => {
              alert(JSON.parse(err.error).message);
              this.content = JSON.parse(err.error).message;
            },    // errorHandler
            next: (data) => {
              alert(data);
              this.mostrarNuevoPlazoFijo = false;
              this.relogearDatos();
              this.content = data;
            }

          }
        );
        this.mostrarNuevoPlazoFijo = false;
        this.relogearDatos();
      }
    }
  }
  cerrarPlazoFijo(plazoFijo: PlazoFijoDTO) {
    if (confirm("¿Estás seguro de cerrar el plazo fijo de " + this.data.cliente + "?") == true) {
      this.adminService.cerrarPlazoFijo(this.data.cliente, plazoFijo.plazoFijoId).subscribe(
        {
          complete: () => { }, // completeHandler
          error: (err) => {
            alert(JSON.parse(err.error).message);
          },    // errorHandler 
          next: (data) => {
            alert(data);
            this.relogearDatos();
          }
        }
      );
    }
  }
  depositar(montoRetiroDeposito: number) {
    if (confirm("¿Estás seguro de depositar " + montoRetiroDeposito + " USDT a " + this.data.cliente + "?") == true) {
      if (montoRetiroDeposito <= 0) {
        alert("El monto no puede ser 0 o menor a 0.")
      } else {
        this.adminService.depositarPlazoFijo(this.data.cliente, montoRetiroDeposito).subscribe(
          {
            complete: () => { }, // completeHandler
            error: (err) => {
              alert(JSON.parse(err.error).message);
              this.content = JSON.parse(err.error).message;
            },    // errorHandler 
            next: (data) => {
              alert(data);
              this.mostrarDepositoORetiro = false;
              this.relogearDatos();
            }
          }
        );
      }
    }
  }
  retirar(montoRetiroDeposito: number) {
    if (confirm("¿Estás seguro de retirar " + montoRetiroDeposito + " USDT de " + this.data.cliente + "?") == true) {
      this.adminService.retirarPlazoFijo(this.data.cliente, montoRetiroDeposito).subscribe(
        {
          complete: () => { }, // completeHandler
          error: (err) => {
            alert(JSON.parse(err.error).message);
            this.content = JSON.parse(err.error).message;
          },    // errorHandler
          next: (data) => {
            alert(data);
            this.mostrarDepositoORetiro = false;
            this.relogearDatos();
            this.content = data;
          }
        }
      );
    }
  }
  formatearFecha() {
    let day = this.formatDate.getDate() < 10 ? `0${this.formatDate.getDate()}` : this.formatDate.getDate() + '';
    let month = this.formatDate.getMonth() + 1 < 10 ? `0${this.formatDate.getMonth() + 1}` : (this.formatDate.getMonth() + 1) + '';;
    let year = this.formatDate.getFullYear();

    this.dateString = "" + year + "-" + month + "-" + day + "";
  }
  transformarStringANumber() {
    let interesAnual = Number(this.interesAnualString);
    this.nuevoPlazoFijoDTO.interesAnual = interesAnual;
  }

  // VER MOVIMIENTOS

  verMovimientoPlazoFijo(movPlazoFijo: MovPlazoFijoDTO) {
    if (movPlazoFijo.operacion == 'INICIO_PLAZO_FIJO') {
      this.adminService.getPlazoFijoByMovPlazoFijoId(movPlazoFijo.movPlazoFijoId, this.data.cliente).subscribe(
        {
          complete: () => { }, // completeHandler
          error: (err) => {
            alert(err.error.message);
            this.modificarMovimientoPlazoFijo = false;
          },    // errorHandler
          next: (data) => {
            this.interesSeleccionado = data.interesAnual.toString();
            this.montoSeleccionado = data.monto;
            this.fechaSeleccionada = data.fechaDeInicio;
            this.idSeleccionado = movPlazoFijo.movPlazoFijoId;
            this.modificarMovimientoPlazoFijo = true;
            this.modificarMovimientoPlazoFijoDepositoORetiro = false;
          }
        });
    } else if (movPlazoFijo.operacion == 'DEPOSITO' || movPlazoFijo.operacion == 'RETIRO' || movPlazoFijo.operacion == 'PAGO_INTERESES') {
      this.adminService.getMovPlazoFijoDepositoORetiroByMovId(movPlazoFijo.movPlazoFijoId, this.data.cliente).subscribe(
        {
          complete: () => { }, // completeHandler
          error: (err) => {
            alert(err.error.message);
            this.modificarMovimientoPlazoFijo = false;
          },    // errorHandler
          next: (data) => {
            this.montoSeleccionado = data;
            this.idSeleccionado = movPlazoFijo.movPlazoFijoId;
            this.modificarMovimientoPlazoFijo = false;
            this.modificarMovimientoPlazoFijoDepositoORetiro = true;
          }
        });
    }
  }
  cerrarVentanaModificar() {
    this.modificarMovimientoPlazoFijo = false;
    this.modificarMovimientoPlazoFijoDepositoORetiro = false;
    this.interesSeleccionado = "";
    this.montoSeleccionado = 0;
    this.fechaSeleccionada = "";
  }
  // CANCELAR MOVIMIENTOS

  cancelarOperacionCierrePlazoFijo(movPlazoFijoId: number) {
    if (confirm("¿Estás seguro de cancelar el movimiento?") == true) {
      this.adminService.cancelarOperacionCierrePlazoFijo(movPlazoFijoId, this.data.cliente).subscribe(
        {
          complete: () => { }, // completeHandler
          error: (err) => {
            alert(err.error.message);
            this.relogearDatos();
          },    // errorHandler
          next: (data) => {
            alert(data);
            this.relogearDatos();
          }
        }
      );
    }
  }
  cancelarMovimientoPlazoFijo() {
    if (confirm("¿Estás seguro de cancelar el movimiento?") == true) {
      this.adminService.cancelarMovimientoPlazoFijo(this.idSeleccionado, this.data.cliente).subscribe(
        {
          complete: () => { }, // completeHandler
          error: (err) => {
            alert(err.error.message)
            this.modificarMovimientoPlazoFijo = false;
            this.relogearDatos();
          },    // errorHandler
          next: (data) => {
            alert(data);
            this.modificarMovimientoPlazoFijo = false;
            this.relogearDatos();
          }
        }
      );
    }
  }

  // MODIFICAR MOVIMIENTO

  modificarMovInicioDePlazoFijo() {
    if (confirm("¿Estás seguro de modificar el movimiento?") == true) {
      if (this.montoSeleccionado > 0 && this.idSeleccionado > 0) {
        this.modMovPlazoFijoDTO.monto = this.montoSeleccionado;
        this.modMovPlazoFijoDTO.fechaDeInicio = this.fechaSeleccionada;
        this.modMovPlazoFijoDTO.interesAnual = Number(this.interesSeleccionado);
        this.adminService.modificarMovInicioDePlazoFijo(this.modMovPlazoFijoDTO, this.idSeleccionado, this.data.cliente).subscribe(
          {
            complete: () => { }, // completeHandler
            error: (err) => {
              alert(JSON.parse(err.error).message);
              this.content = JSON.parse(err.error).message;
            },    // errorHandler
            next: (data) => {
              alert(data);
              this.modificarMovimientoPlazoFijo = false;
              this.relogearDatos();
            }
          }
        );
      } else alert('Verifique los campos.');
    }
  }
  modificarMovDepositoORetiroDePlazoFijo() {
    if (confirm("¿Estás seguro de modificar el movimiento?") == true) {
      if (this.montoSeleccionado >= 0 && this.idSeleccionado > 0) {
        this.adminService.modificarMovDepositoORetiroDePlazoFijo(this.montoSeleccionado, this.idSeleccionado, this.data.cliente).subscribe(
          {
            complete: () => { }, // completeHandler
            error: (err) => {
              alert(JSON.parse(err.error).message);
              this.content = JSON.parse(err.error).message;
            },    // errorHandler
            next: (data) => {
              alert(data);
              this.modificarMovimientoPlazoFijoDepositoORetiro = false;
              this.relogearDatos();
            }
          }
        );
      } else alert("Verifique los campos.");
    }
  }
  agregarCampoFunction() {
    this.agregarMovPasadoDTO = new AgregarMovPasadoDTO(0,"","");
    this.movimientoPasadoArray.push(this.agregarMovPasadoDTO);
  }
  borrarCampoFunction(index: number){
    this.movimientoPasadoArray.splice(index,1);
  }
  onSubmit(){
    if (confirm("¿Estás seguro de generar estos movimientos?") == true) {
      let campoVacio = false;
      this.movimientoPasadoArray.forEach(element => {
        if(element.monto <= 0 || element.fechaDeOperacion == "" || element.operacion == ""){
          campoVacio = true;
        }
      });
      if (campoVacio) {
        alert("Verifique los campos.")
      } else {
        this.adminService.agregarMovimientosPasados(this.data.cliente, this.movimientoPasadoArray, Number(this.interesSeleccionado)).subscribe(
          {
            complete: () => { }, // completeHandler
            error: (err) => {
              alert(JSON.parse(err.error).message);
              this.content = JSON.parse(err.error).message;
            },    // errorHandler 
            next: (data) => {
              alert(data);
              this.relogearDatos();
            }
          }
        );
      }
    }
  }
}