<!-- <div class="scrolling-container">
    <h2 style="margin: 0">BTC: <span [ngStyle]="{'color': btcToUsdtPrev > btcToUsdt ? '#ec3f3f' : '#54d33b'}">{{btcToUsdt | currency:'USD'}}</span></h2>
    <h2 style="margin: 0">ETH: <span [ngStyle]="{'color': ethToUsdtPrev > ethToUsdt ? '#ec3f3f' : '#54d33b'}">{{ethToUsdt | currency:'USD'}}</span></h2>
    <h2 style="margin: 0">ETH: <span [ngStyle]="{'color': ethToUsdtPrev > ethToUsdt ? '#ec3f3f' : '#54d33b'}">{{ethToUsdt | currency:'USD'}}</span></h2>
    <h2 style="margin: 0">ETH: <span [ngStyle]="{'color': ethToUsdtPrev > ethToUsdt ? '#ec3f3f' : '#54d33b'}">{{ethToUsdt | currency:'USD'}}</span></h2>
    <h2 style="margin: 0">ETH: <span [ngStyle]="{'color': ethToUsdtPrev > ethToUsdt ? '#ec3f3f' : '#54d33b'}">{{ethToUsdt | currency:'USD'}}</span></h2>
    <h2 style="margin: 0">ETH: <span [ngStyle]="{'color': ethToUsdtPrev > ethToUsdt ? '#ec3f3f' : '#54d33b'}">{{ethToUsdt | currency:'USD'}}</span></h2>
    <h2 style="margin: 0">ETH: <span [ngStyle]="{'color': ethToUsdtPrev > ethToUsdt ? '#ec3f3f' : '#54d33b'}">{{ethToUsdt | currency:'USD'}}</span></h2>
</div> -->
<div class="banner-area">
    
    <div class="container-fluid web-display">
        
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="banner-image" >
                    <img src="assets/img/home-photo2.jpg" class="banner-img-3" alt="banner-image">
                    <img src="assets/img/home-photo1.jpg" class="banner-img-7" alt="banner-image">
                    <img src="assets/img/home-photo3.jpg" class="banner-img-8" alt="banner-image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="banner-content">
                    <h1>Trading, exchange y construcción en <span class="blockchain">Blockchain</span></h1>
                    <p>Llevamos tu proyecto a la blockchain bloque por bloque</p>
                    <a href="https://linktr.ee/hashrate" target="_blank" class="default-btn btn-active">
                        CONTACTANOS
                        <img class="ms-2" src="assets/images/icon/arrow-right.svg" alt="icon">
                    </a>
                    <img src="assets/images/banner/banner-shape-4.png" class="shape banner-shape-4" alt="shape">
                    <img src="assets/images/banner/banner-shape-9.png" class="shape banner-shape-9" alt="shape">
                    <img src="assets/images/banner/banner-shape-10.png" class="shape banner-shape-10" alt="shape">
                </div>
            </div>

        </div>

    </div>
    <div class="container-fluid mobile-display">
        
        <div class="row align-items-center">
            
            <div class="col-lg-6 col-md-12">
                <div class="banner-content">
                    <h1>Trading, exchange y construcción en <span class="blockchain">Blockchain</span></h1>
                    <p>Llevamos tu proyecto a la blockchain bloque por bloque</p>
                    <a href="https://linktr.ee/hashrate" target="_blank" class="default-btn btn-active">
                        CONTACTANOS
                        <img class="ms-2" src="assets/images/icon/arrow-right.svg" alt="icon">
                    </a>
                    <img src="assets/images/banner/banner-shape-4.png" class="shape banner-shape-4" alt="shape">
                    <img src="assets/images/banner/banner-shape-9.png" class="shape banner-shape-9" alt="shape">
                    <img src="assets/images/banner/banner-shape-10.png" class="shape banner-shape-10" alt="shape">
                </div>
            </div>

            <div class="col-lg-6 col-md-12 mt-3">
                <div class="banner-image" >
                    <img src="assets/img/home-photo2.jpg" class="banner-img-7" alt="banner-image">
                    <img src="assets/img/home-photo1.jpg" class="banner-img-7" alt="banner-image">
                    <img src="assets/img/home-photo3.jpg" class="banner-img-8" alt="banner-image">
                </div>
            </div>

        </div>

    </div>
    <img src="assets/images/banner/banner-shape-1.png" class="shape shape-1" alt="shape">
    <img src="assets/images/banner/banner-shape-14.png" class="shape shape-7" alt="shape">
</div>
