export class ClientePerfilDetallado {
  email: string;
  nombre: string;
  apellido:string;
  password:string;
  roles:string;
  fechaDeCreacion:String;
  fechaDeModificacion:string;
  fechaDeBaja:string;
  dni:number;
  telefono:number;


  constructor( email: string,  nombre: string,apellido: string,
    roles: string, password:string, fechaDeCreacion: string,  fechaDeModificacion: string,
    fechaDeBaja: string,  capitalDisponible: number,
    capitalInvertido: number,  dni: number, telefono: number) {
    this.email =email;
    this.nombre = nombre;
    this.apellido = apellido;
    this.password = password;
    this.roles = roles;
    this.fechaDeCreacion = fechaDeCreacion;
    this.fechaDeModificacion = fechaDeModificacion;
    this.fechaDeBaja = fechaDeBaja;
    this.dni = dni;
    this.telefono = telefono;
  }
}
