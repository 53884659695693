<mat-card class="example-card">
  <div class="menuPlazoFijo">
    <button mat-button  (click)="mostrarPlazoFijoFunction()">Mostrar Plazos Fijos</button>
    <button mat-button  (click)="mostrarMovPlazoFijoFunction()">Mostrar Movimientos</button>
    <button mat-button  (click)="mostrarHistorialAutomaticoFunction()">Mostrar Historial
      Automático</button>
    <button mat-button  (click)="mostrarAgregarMovimientoFunction()">Agregar Movimiento Pasado</button>
  </div>
  <mat-card-header>
    <mat-card-title>
      <h1 *ngIf="mostrarPlazoFijo">Plazos Fijos de <span class="resaltado">{{data.cliente}}</span></h1>
      <h1 *ngIf="mostrarMovPlazoFijo">Movimientos de <span class="resaltado">{{data.cliente}}</span></h1>
      <h1 *ngIf="mostrarHistorialAutomatico">Historial de <span class="resaltado">{{data.cliente}}</span></h1>
    </mat-card-title>
    <mat-card-subtitle>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div class="container-titulo" *ngIf="plazoFijoAbierto.length == 0 && plazoFijoCerrado.length == 0">
      <h1>El cliente no tiene ningun plazo fijo.</h1>
    </div>
    <header class="jumbotron" *ngIf="mostrarDepositoORetiro">
      <h2>Depositar / Retirar</h2>

      <mat-form-field class="twoFieldRow">
        <input matInput [(ngModel)]="data.cliente" type="text" readonly="readonly" placeholder="Email">
      </mat-form-field>

      $<mat-form-field class="twoFieldRow">
        <input matInput [(ngModel)]="montoRetiroDeposito" type="number" min="0" placeholder="Monto">
      </mat-form-field>
      <div class="acciones-container">
        <button mat-button  (click)="depositar(montoRetiroDeposito)">Depositar</button>
        <button mat-button  (click)="retirar(montoRetiroDeposito)">Retirar</button>
      </div>
    </header>
    <header class="jumbotron" *ngIf="mostrarNuevoPlazoFijo">
      <h2>Crear Plazo Fijo</h2>
      <mat-form-field appearance="fill">
        <mat-label>Fecha de inicio (MM/DD/YYYY)</mat-label>
        <input matInput [matDatepicker]="picker" [min]="minDate" [(ngModel)]="formatDate" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="fiveFieldRow">
        <input matInput [(ngModel)]="nuevoPlazoFijoDTO.montoInvertido" type="number" min="0"
          placeholder="Monto invertido">
      </mat-form-field>

      <mat-form-field class="fiveFieldRow">
        <mat-label>Interés anual</mat-label>
        <select matNativeControl required [(ngModel)]="interesAnualString" readonly>
          <option value="8">8%</option>
          <option value="9">9%</option>
          <option value="10">10%</option>
          <option value="11">11%</option>
          <option value="12">12%</option>
          <option value="13">13%</option>
          <option value="14">14%</option>
          <option value="15">15%</option>
          <option value="16">16%</option>
          <option value="17">17%</option>
          <option value="18">18%</option>
          <option value="19">19%</option>
          <option value="20">20%</option>
        </select>
      </mat-form-field>
      <div class="acciones-container">
        <button mat-button  (click)="crearPlazoFijo()">Crear plazo fijo</button>
      </div>
    </header>
    <header class="jumbotron" *ngIf="modificarMovimientoPlazoFijo">
      <h2>Modificar Plazo Fijo</h2>
      <mat-form-field appearance="fill">
        <mat-label>Fecha de inicio (MM/DD/YYYY)</mat-label>
        <input matInput [matDatepicker]="picker" [min]="minDate" [(ngModel)]="fechaSeleccionada" readonly>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="threeFieldRow">
        <input matInput [(ngModel)]="montoSeleccionado" type="number" min="0" placeholder="Monto invertido">
      </mat-form-field>

      <mat-form-field class="threeFieldRow">
        <mat-label>Interés anual</mat-label>
        <mat-select matNativeControl required [(value)]="interesSeleccionado" readonly>
          <mat-option value="8">8%</mat-option>
          <mat-option value="9">9%</mat-option>
          <mat-option value="10">10%</mat-option>
          <mat-option value="11">11%</mat-option>
          <mat-option value="12">12%</mat-option>
          <mat-option value="13">13%</mat-option>
          <mat-option value="14">14%</mat-option>
          <mat-option value="15">15%</mat-option>
          <mat-option value="16">16%</mat-option>
          <mat-option value="17">17%</mat-option>
          <mat-option value="18">18%</mat-option>
          <mat-option value="19">19%</mat-option>
          <mat-option value="20">20%</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="acciones-container">
        <button mat-button  (click)="modificarMovInicioDePlazoFijo()">Modificar Plazo
          Fijo</button>
        <button mat-button  (click)="cancelarMovimientoPlazoFijo()">Cancelar operación</button>
        <button mat-button  (click)="cerrarVentanaModificar()">Cerrar</button>
      </div>
    </header>
    <header class="jumbotron" *ngIf="modificarMovimientoPlazoFijoDepositoORetiro">
      <h2>Modificar Plazo Fijo</h2>
      $<mat-form-field class="oneFieldRow">
        <input matInput [(ngModel)]="montoSeleccionado" type="number" min="0" placeholder="Monto">
      </mat-form-field>
      <div class="acciones-container">
        <button mat-button  (click)="modificarMovDepositoORetiroDePlazoFijo()">Modificar</button>
        <button mat-button  (click)="cancelarMovimientoPlazoFijo()">Cancelar
          operación</button>
        <button mat-button  (click)="cerrarVentanaModificar()">Cerrar</button>
      </div>
    </header>
    <header class="jumbotron" *ngIf="mostrarAgregarMovimiento">
      <form (ngSubmit)="onSubmit()">
        <h2>Agregar Movimiento</h2>
        <div *ngFor="let objeto of movimientoPasadoArray; let i = index">
          <mat-form-field appearance="fill">
            <mat-label>Fecha del movimiento (MM/DD/YYYY)</mat-label>
            <input matInput [matDatepicker]="picker" name="fechaDeOperacion{{i}}" [(ngModel)]="objeto.fechaDeOperacion"
              readonly>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
          <mat-form-field *ngIf="objeto.operacion != 'CIERRE_PLAZO_FIJO'" class="oneFieldRow">
            <div class="div-signo-pesos">
              <span>$</span><input matInput name="monto{{i}}" [(ngModel)]="objeto.monto" type="number" min="0" placeholder="Monto">
            </div>
          </mat-form-field>
          <mat-form-field class="threeFieldRow">
            <mat-label>Operación</mat-label>
            <mat-select matNativeControl required [(value)]="objeto.operacion">
              <mat-option *ngIf="i == 0" value="INICIO_PLAZO_FIJO">Inicio de plazo fijo</mat-option>
              <mat-option *ngIf="i != 0" value="DEPOSITO">Depósito</mat-option>
              <mat-option *ngIf="i != 0" value="RETIRO">Retiro</mat-option>
              <mat-option *ngIf="i != 0" value="PAGO_INTERESES">Pago de intereses</mat-option>
              <mat-option *ngIf="i != 0" value="CIERRE_PLAZO_FIJO">Cierre de plazo fijo</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field *ngIf="i == 0" class="threeFieldRow">
            <mat-label>Interés anual</mat-label>
            <mat-select matNativeControl required [(value)]="interesSeleccionado" readonly>
              <mat-option value="8">8%</mat-option>
              <mat-option value="9">9%</mat-option>
              <mat-option value="10">10%</mat-option>
              <mat-option value="11">11%</mat-option>
              <mat-option value="12">12%</mat-option>
              <mat-option value="13">13%</mat-option>
              <mat-option value="14">14%</mat-option>
              <mat-option value="15">15%</mat-option>
              <mat-option value="16">16%</mat-option>
              <mat-option value="17">17%</mat-option>
              <mat-option value="18">18%</mat-option>
              <mat-option value="19">19%</mat-option>
              <mat-option value="20">20%</mat-option>
            </mat-select>
          </mat-form-field>
          <button mat-button *ngIf="i>0"  (click)="borrarCampoFunction(i)"><i
              class='fas fa-minus fa-lg'></i></button>
        </div>
        <button mat-button  type="button" (click)="agregarCampoFunction()"><i
            class='fas fa-plus fa-lg'></i></button>
        <button mat-button  type="submit">Enviar Movimientos</button>
      </form>
    </header>






    <!-- <header class="jumbotron" *ngIf="mostrarAgregarMovimiento">
      <div>
        <h2>Agregar Movimiento</h2>
        <mat-form-field appearance="fill">
          <mat-label>Fecha del movimiento (MM/DD/YYYY)</mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="fechaSeleccionada" readonly>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        $<mat-form-field class="oneFieldRow">
          <input matInput [(ngModel)]="montoSeleccionado" type="number" min="0" placeholder="Monto">
        </mat-form-field>
        <mat-form-field class="threeFieldRow">
          <mat-label>Operación</mat-label>
          <mat-select matNativeControl required [(value)]="operacionSeleccionada">
            <mat-option value="INICIO_PLAZO_FIJO">Inicio de plazo fijo</mat-option>
            <mat-option value="DEPOSITO">Depósito</mat-option>
            <mat-option value="RETIRO">Retiro</mat-option>
            <mat-option value="PAGO_INTERES">Pago de intereses</mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <button mat-button  (click)="agregarCampoFunction()"><i class='fas fa-plus fa-lg'></i></button>
      <div class="acciones-container">
        <button mat-button  (click)="agregarMovimientoPasadoFunction()">Confirmar</button>
      </div>
    </header> -->





    <div style="margin:1rem auto; width: 90%; height: 90%;padding: 1rem;">
      <div [hidden]="!mostrarPlazoFijo || plazoFijoAbierto.length == 0" class="contenedor-plazoFijo">

        <h2 style="text-align: center;">Plazo Fijo Abierto</h2>
        <!-- <mat-form-field>
          <mat-label>Buscar</mat-label>
          <input matInput (keyup)="filtrarPlazosFijosAbiertos($event)" placeholder="buscar">
        </mat-form-field> -->
        <table mat-table [dataSource]="dataSourcePlazoFijoAbierto" class="mat-elevation-z8" #tablaPlazoFijoAbierto
          matSort #sortPlazoFijoAbierto="matSort" matSortActive="fechaDeInicio" matSortDirection="desc"
          matSortDisableClear>

          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="fechaDeInicio">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de Inicio </th>
            <td mat-cell *matCellDef="let plazoFijoAbierto"> {{plazoFijoAbierto.fechaDeInicio}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="montoInvertido">
            <th mat-header-cell *matHeaderCellDef> Monto invertido </th>
            <td mat-cell *matCellDef="let plazoFijoAbierto"> ${{plazoFijoAbierto.montoInvertido}}
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="mesesCumplidos">
            <th mat-header-cell *matHeaderCellDef> Meses cumplidos </th>
            <td mat-cell *matCellDef="let plazoFijoAbierto"> {{plazoFijoAbierto.mesesCumplidos}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="interesAnual">
            <th mat-header-cell *matHeaderCellDef> Interés </th>
            <td mat-cell *matCellDef="let plazoFijoAbierto"> {{plazoFijoAbierto.interesAnual}}%
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="montoAcumuladoMensual">
            <th mat-header-cell *matHeaderCellDef> Monto acumulado mensual </th>
            <td mat-cell *matCellDef="let plazoFijoAbierto"> ${{plazoFijoAbierto.montoAcumuladoMensual}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="gananciaNeta">
            <th mat-header-cell *matHeaderCellDef> Ganancia neta </th>
            <td mat-cell *matCellDef="let plazoFijoAbierto"> ${{plazoFijoAbierto.gananciaNeta}}
            </td>
          </ng-container>

          <ng-container matColumnDef="cancelacion">
            <th mat-header-cell *matHeaderCellDef> Cancelacion</th>
            <td mat-cell *matCellDef="let plazoFijoAbierto"><button mat-button 
                (click)="cerrarPlazoFijo(plazoFijoAbierto)">Cerrar plazo fijo</button></td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsPlazoFijoAbierto"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsPlazoFijoAbierto;"></tr>
        </table>

      </div>
      <div [hidden]="!mostrarPlazoFijo || plazoFijoCerrado.length == 0" class="contenedor-plazoFijo">
        <h2 style="text-align: center;">Plazos Fijos Cerrados</h2>
        <mat-form-field>
          <mat-label>Buscar</mat-label>
          <input matInput (keyup)="filtrarPlazosFijosCerrados($event)" placeholder="buscar">
        </mat-form-field>
        <table mat-table [dataSource]="dataSourcePlazoFijoCerrado" class="mat-elevation-z8" #tablaPlazoFijoCerrado
          matSort #sortPlazoFijoCerrado="matSort" matSortActive="fechaDeInicio" matSortDirection="desc"
          matSortDisableClear>
          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="fechaDeInicio">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de inicio</th>
            <td mat-cell *matCellDef="let plazoFijoCerrado"> {{plazoFijoCerrado.fechaDeInicio}} </td>
          </ng-container>

          <ng-container matColumnDef="fechaDeCierre">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de cierre </th>
            <td mat-cell *matCellDef="let plazoFijoCerrado"> {{plazoFijoCerrado.fechaDeCierre}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="mesesCumplidos">
            <th mat-header-cell *matHeaderCellDef> Meses cumplidos </th>
            <td mat-cell *matCellDef="let plazoFijoCerrado"> {{plazoFijoCerrado.mesesCumplidos}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="interesAnual">
            <th mat-header-cell *matHeaderCellDef> Interés </th>
            <td mat-cell *matCellDef="let plazoFijoCerrado"> {{plazoFijoCerrado.interesAnual}}%
            </td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="gananciaNeta">
            <th mat-header-cell *matHeaderCellDef> Ganancia neta </th>
            <td mat-cell *matCellDef="let plazoFijoCerrado"> ${{plazoFijoCerrado.gananciaNeta}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsPlazoFijoCerrado"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsPlazoFijoCerrado;"></tr>
        </table>
        <mat-paginator matPaginator #paginatorPlazoFijoCerrado='matPaginator' [pageSizeOptions]="[5, 10, 15]"
          showFirstLastButtons></mat-paginator>
      </div>
      <div [hidden]="!mostrarMovPlazoFijo">
        <mat-form-field>
          <mat-label>Buscar</mat-label>
          <input matInput (keyup)="filtrarMovimientos($event)" placeholder="buscar">
        </mat-form-field>
        <table mat-table [dataSource]="dataSourceMovPlazoFijo" class="mat-elevation-z8" #tablaMovPlazoFijo matSort
          #sortMovPlazoFijo="matSort" matSortActive="fechaDeCreacion" matSortDirection="desc" matSortDisableClear>

          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="fechaDeCreacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creacion </th>
            <td mat-cell *matCellDef="let movPlazoFijo"> {{movPlazoFijo.fechaDeCreacion}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="monto">
            <th mat-header-cell *matHeaderCellDef> Monto </th>
            <td mat-cell *matCellDef="let movPlazoFijo"> ${{movPlazoFijo.monto}} </td>
          </ng-container>

          <ng-container matColumnDef="operacion">
            <th mat-header-cell *matHeaderCellDef> Operación </th>
            <td mat-cell *matCellDef="let movPlazoFijo"> {{movPlazoFijo.operacion}} </td>
          </ng-container>

          <ng-container matColumnDef="transaccion">
            <th mat-header-cell *matHeaderCellDef> Transacción </th>
            <td mat-cell *matCellDef="let movPlazoFijo"> {{movPlazoFijo.transaccion}} </td>
          </ng-container>

          <ng-container matColumnDef="acciones">
            <th mat-header-cell *matHeaderCellDef> Acciones</th>
            <td mat-cell *matCellDef="let movPlazoFijo">
              <button *ngIf="movPlazoFijo.operacion != 'CIERRE_PLAZO_FIJO'" mat-button  focusable="false"
                (click)="verMovimientoPlazoFijo(movPlazoFijo)">Ver</button>
              <button *ngIf="movPlazoFijo.operacion == 'CIERRE_PLAZO_FIJO'" mat-button  focusable="false"
                (click)="cancelarOperacionCierrePlazoFijo(movPlazoFijo.movPlazoFijoId)">Cancelar
                operacion</button>
            </td>
          </ng-container>
          <tr mat-header-row *matHeaderRowDef="displayedColumnsMovPlazoFijo"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsMovPlazoFijo;"></tr>
        </table>
        <mat-paginator matPaginator #paginatorMovPlazoFijo='matPaginator' [pageSizeOptions]="[5, 10, 15]"
          showFirstLastButtons>
        </mat-paginator>
      </div>
      <div [hidden]="!mostrarHistorialAutomatico" class="contenedor-plazoFijo">
        <h2 style="text-align: center;">Historial Automático</h2>
        <mat-form-field>
          <mat-label>Buscar</mat-label>
          <input matInput (keyup)="filtrarHistorialAutomatico($event)" placeholder="buscar">
        </mat-form-field>
        <table mat-table [dataSource]="dataSourceHistorialAutomatico" class="mat-elevation-z8" #tablaHistorialAutomatico
          matSort #sortHistorialAutomatico="matSort" matSortActive="fechaDeCreacion" matSortDirection="desc"
          matSortDisableClear>

          <!--- Note that these columns can be defined in any order.
                  The actual rendered columns are set as a property on the row definition" -->

          <!-- Position Column -->
          <ng-container matColumnDef="fechaDeCreacion">
            <th mat-header-cell *matHeaderCellDef mat-sort-header> Fecha de creacion </th>
            <td mat-cell *matCellDef="let historialAutomatico"> {{historialAutomatico.fechaDeCreacion}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="monto">
            <th mat-header-cell *matHeaderCellDef> Monto </th>
            <td mat-cell *matCellDef="let historialAutomatico"> ${{historialAutomatico.monto}} </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="operacion">
            <th mat-header-cell *matHeaderCellDef> Operación </th>
            <td mat-cell *matCellDef="let historialAutomatico"> {{historialAutomatico.operacion}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumnsHistorialAutomatico"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumnsHistorialAutomatico;"></tr>
        </table>
        <mat-paginator matPaginator #paginatorHistorialAutomatico='matPaginator' [pageSizeOptions]="[5, 10, 15]"
          showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </mat-card-content>
  <mat-card-footer>
    <!-- <div *ngIf="mostrarPlazoFijo">
      <div class="contenedor-cuentaCorriente">
        <button mat-button (click)="mostrarNuevoPlazoFijoFunction()">Crear Plazo
          Fijo</button>
        <button mat-button (click)="mostrarDepositoORetiroFunction()">Depositar/Retirar</button>
      </div>
    </div> -->
    <div mat-dialog-actions>
      <div *ngIf="mostrarPlazoFijo">
        <button mat-button  (click)="mostrarNuevoPlazoFijoFunction()">Crear Plazo
          Fijo</button>
        <button mat-button  (click)="mostrarDepositoORetiroFunction()">Depositar/Retirar</button>
      </div>
      <button mat-button  (click)="relogearDatos()"><i class="fa fa-refresh"></i></button>
      <button mat-button  (click)="cancelar()">Cerrar</button>
    </div>
  </mat-card-footer>
</mat-card>