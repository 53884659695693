import { MonedasPrecio } from "./monedas-precio";

export class CierreBalance {
  email:string;
  monedas:MonedasPrecio[];


  constructor( email: string,  monedas: MonedasPrecio[]) {
    this.email =email;
    this.monedas = monedas;
  }
}
