export class ModMovPlazoFijoDTO {

    monto: number;
    fechaDeInicio: string;
    interesAnual: number;

    constructor(monto: number, fechaDeInicio: string, interesAnual: number) {
        this.monto = monto;
        this.fechaDeInicio = fechaDeInicio;
        this.interesAnual = interesAnual;
    }
}
