export class MovimientoDTO {
  movimientoId:number;
  balanceId:number;
  fecha:string;
  transaccion:string;
  monto:number;


  constructor(movimientoId:number, balanceId:number, fecha: string,  transaccion: string,  monto: number) {
    this.movimientoId =movimientoId;
    this.balanceId =balanceId;
    this.fecha =fecha;
    this.transaccion = transaccion;
    this.monto = monto;
  }
}
