import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { BilleteraDTO } from 'src/app/class/billetera/billetera-dto';
import { EventBusService } from 'src/app/security/event-bus.service'
import { PublicService } from 'src/app/public/service/public.service';
import { BinanceCoins } from 'src/app/class/coins/binance-coins';
import { CompraVentaDeMoneda } from 'src/app/class/billetera/compra-venta-de-moneda';
import { DepositoDTO } from 'src/app/class/billetera/deposito-dto';
import { AdminService } from '../../service/admin.service';
import { EventData } from 'src/app/security/event.class';
import { InversionDTO } from 'src/app/class/billetera/inversion-dto';




@Component({
  selector: 'app-billetera-dtocliente-dialogo',
  templateUrl: './billetera-dtocliente-dialogo.component.html',
  styleUrls: ['./billetera-dtocliente-dialogo.component.css']
})
export class BilleteraDTOClienteDialogoComponent implements OnInit {
  msjAlert:string='';
  depositarRetirarUSDTMsj= "El deposito y retiro se aplican a los USDT del cliente.";
  venderOComprarMsj= "El precio de la moneda es a valor USDT.";
  displayedColumns: string[] = [ 'moneda','cantidad','precio','total'];
  binanceCoin: BinanceCoins={symbol:'',price:'0'};
  billetera: BilleteraDTO[]=[];
  precio:number=0;
  mondeaExistente=false;
  monedaEncontrada:boolean=false;
  total:number=0;
  isComprarVenderCrypto=true;
  isDepositarRetirarUSDT=false;


  @ViewChild(MatTable) tablaBilletera!: MatTable<BilleteraDTO>;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  dataSource!: MatTableDataSource<BilleteraDTO>;



  constructor(
    public dialogRef: MatDialogRef<BilleteraDTOClienteDialogoComponent>,
    @ Inject(MAT_DIALOG_DATA) public data:{ billetera:BilleteraDTO[],
      compraVentaDeMoneda:CompraVentaDeMoneda, deposito: DepositoDTO,inversion: InversionDTO},
    private publicService: PublicService,private adminService: AdminService, private eventBusService: EventBusService) {}

  ngOnInit() {
          this.data.billetera.forEach(moneda=> this.setmondedaPrecioYTotal(moneda));
          this.relogearDatos(this.data.deposito.email);
          this.reloadTable();  // nextHandler
     registerLocaleData( es );}     // nextHandler


  cancelar() {
    this.dialogRef.close();
  }
  relogearDatos(email:string){
    this.billetera=[];
    this.adminService.getBilleteraDelClienteByEmail(email).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.venderOComprarMsj = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (data) => {
          this.data.billetera=data;
          this.data.billetera.forEach(moneda=> this.setmondedaPrecioYTotal(moneda));
          this.reloadTable();},     // nextHandler
    }

    );
    this.data.billetera.forEach(moneda=> this.setmondedaPrecioYTotal(moneda));
    this.reloadTable();
    registerLocaleData( es );
    this.reloadTable();
  }

  reloadTable(){
    this.total=0;
    this.data.billetera.forEach(m=> this.total= this.total+m.total);
    this.dataSource = new MatTableDataSource<BilleteraDTO>(this.data.billetera);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;

  }

  setmondedaPrecioYTotal(moneda: BilleteraDTO): void {
    this.binanceCoin = this.serchMoneda(moneda.moneda);
  }
  serchMoneda(moneda: string): BinanceCoins {
    this.mondeaExistente=true
    if(moneda=='USDT'){
      var foundIndex = this.data.billetera.findIndex(x => x.moneda == moneda);
      this.data.billetera[foundIndex] =new BilleteraDTO(moneda,this.data.billetera[foundIndex].cantidad,1,this.data.billetera[foundIndex].cantidad*1);
        this.reloadTable();
    }
    else if (this.mondeaExistente){
     this.monedaEncontrada= this.buscarPrecioEnBinanceReturn(moneda);
    }
    if (!this.monedaEncontrada){
      this.binanceCoin= this.buscarPrecioEnOKXReturn(moneda);

    }
    return this.binanceCoin ;
  }
  changeCantidad(cantidadValue: number) {
    if(this.data.compraVentaDeMoneda.montoUsd!=0 )
    {this.precio =  this.data.compraVentaDeMoneda.montoUsd/ cantidadValue;}
    else
    {this.data.compraVentaDeMoneda.montoUsd = cantidadValue* this.precio;}
  }
  changeMonto(precioValue: number) {
    this.data.compraVentaDeMoneda.montoUsd = precioValue* this.data.compraVentaDeMoneda.cantidad;
  }
  changePrecio(montoValue: number) {
    if(this.data.compraVentaDeMoneda.cantidad!=0){
    this.precio = montoValue/ this.data.compraVentaDeMoneda.cantidad;}
    else if( this.precio!=0 ){

      this.data.compraVentaDeMoneda.cantidad = montoValue/ this.precio;
    }
  }
  changeMondeda(monedaValue: string) {
    this.publicService.getCurrentCoin(monedaValue).subscribe(
    {
      complete: () => {  }, // completeHandler
      error: (err) => {
        this.mondeaExistente=false;
        this.venderOComprarMsj = " La moneda "+monedaValue+"USDT no existe en binance!";},    // errorHandler
      next: (data) => {
        this.venderOComprarMsj = " La moneda "+monedaValue+"USDT existe y tiene un valor actual de : " +data.price;
          this.precio = data.price;
          this.mondeaExistente=true;
          this.reloadTable();
      }
    }
  );
  if( this.mondeaExistente==false){
    this.publicService.getCurrentCoinOKX(monedaValue).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.mondeaExistente=false;
          this.venderOComprarMsj += " La moneda "+monedaValue+" no existe en binace y ni en OKX!";},    // errorHandler
        next: (data) => {
          this.venderOComprarMsj = " La moneda "+monedaValue+" existe en OKX y tiene un valor actual de : " +data.price;
            this.precio = data.price;
            this.binanceCoin=data;
            this.mondeaExistente=true;
            this.reloadTable();
        }
      }
    );

  }
  }

  comprarCrypto(monedaAComprar:CompraVentaDeMoneda){
    this.adminService.comprarMonedaCliente(monedaAComprar).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.venderOComprarMsj = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (movimientoDTO) => {
          this.venderOComprarMsj =  "Operacion: "+movimientoDTO.movimientoId+" "+movimientoDTO.transaccion+
           " de monto "+ movimientoDTO.monto +" en el momento "+movimientoDTO.fecha+
           " con la cuenta del cliente "+monedaAComprar.cliente;
          console.log(movimientoDTO);
          this.relogearDatos(monedaAComprar.cliente);
          this.reloadTable();},     // nextHandler
    }
    );
  }


  venderCrypto(monedaAVender:CompraVentaDeMoneda){
    this.adminService.venderMonedaCliente(monedaAVender).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.venderOComprarMsj = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (movimientoDTO) => {
          this.venderOComprarMsj =  "Operacion: "+movimientoDTO.movimientoId+" "+movimientoDTO.transaccion+
           " de monto"+ movimientoDTO.monto +" en el momento "+movimientoDTO.fecha+
           " con la cuenta del cliente"+monedaAVender.cliente;
          console.log(movimientoDTO);
          this.relogearDatos(monedaAVender.cliente);
          this.reloadTable();},     // nextHandler
    }
    );
  }



  depositar(depositoDTO: DepositoDTO) {
    this.adminService.depositarCliente(depositoDTO).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.depositarRetirarUSDTMsj = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (movimientoDTO) => {
          this.depositarRetirarUSDTMsj = "Se sumaron "+depositoDTO.monto+" USDT al cliente "+depositoDTO.email;
          console.log(movimientoDTO);
          this.relogearDatos(depositoDTO.email);
          this.reloadTable();},     // nextHandler
    }
    );
  }


  getMontoTotal() {
    return this.total;
  }
  retirar(retirarUsd: DepositoDTO) {
    this.adminService.retirarCliente(retirarUsd).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.depositarRetirarUSDTMsj = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (movimientoDTO) => {
          this.depositarRetirarUSDTMsj = "Se restaron "+retirarUsd.monto+" USDT al cliente "+retirarUsd.email;
          console.log(movimientoDTO);
          this.relogearDatos(retirarUsd.email);
          this.reloadTable();},     // nextHandler
    }
    );
  }

  toggleComprarVenderCrypto(){
    this.isComprarVenderCrypto= true;
    this.isDepositarRetirarUSDT= false;
  }
  toggleDepositarUSDT(){
    this.isComprarVenderCrypto= false;
    this.isDepositarRetirarUSDT= true;
  }



  buscarPrecioEnBinance(monedaValue: string){
    this.publicService.getCurrentCoin(monedaValue).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.mondeaExistente=false;
          this.venderOComprarMsj = " La moneda "+monedaValue+" no existe en binance!";
          this.binanceCoin={symbol:'',price:'0'};},    // errorHandler
        next: (data) => {
          this.venderOComprarMsj = " La moneda "+monedaValue+" existe y tiene un valor actual de : " +data.price;
            this.precio = data.price;
            this.binanceCoin=data;
            alert(this.binanceCoin.symbol+'|'+this.binanceCoin.price);
            this.mondeaExistente=true;
            this.reloadTable();
        }
      }
    );
  }


  buscarPrecioEnOKX(monedaValue: string){
    this.publicService.getCurrentCoinOKX(monedaValue).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.mondeaExistente=false;
          this.venderOComprarMsj += " La moneda "+monedaValue+" no existe en OKX!";},    // errorHandler
        next: (data) => {
          this.venderOComprarMsj = " La moneda "+monedaValue+" existe y tiene un valor actual de : " +data.price;
            this.precio = data.price;
            this.binanceCoin=data;
            this.mondeaExistente=true;
            this.reloadTable();
        }
      }
    );

  }
  buscarPrecioEnOKXReturn(monedaValue: string): BinanceCoins{
    this.publicService.getCurrentCoinOKX(monedaValue).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.binanceCoin={symbol:'',price:'0'};},    // errorHandler
        next: (data) => {
          var foundIndex = this.data.billetera.findIndex(x => x.moneda == monedaValue);
          this.data.billetera[foundIndex] =new BilleteraDTO(monedaValue,this.data.billetera[foundIndex].cantidad,+data.price,this.data.billetera[foundIndex].cantidad*+data.price);
          this.reloadTable();
        }
      }
    );
    return this.binanceCoin;

  }

  buscarPrecioEnBinanceReturn(monedaValue: string): boolean {
    this.publicService.getCurrentCoin(monedaValue).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.monedaEncontrada=false;
          this.binanceCoin={symbol:'',price:'0'};},    // errorHandler
        next: (data) => {
          this.monedaEncontrada=true;
            var foundIndex = this.data.billetera.findIndex(x => x.moneda == monedaValue);
            this.data.billetera[foundIndex] =new BilleteraDTO(monedaValue,this.data.billetera[foundIndex].cantidad,+data.price,this.data.billetera[foundIndex].cantidad*+data.price);
            this.reloadTable();
        }
      }
    );

    return this.monedaEncontrada;
  }

}


