import { Component, OnInit , ViewChild} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { EventBusService } from 'src/app/security/event-bus.service';
import { ClientePerfil } from 'src/app/class/cliente/cliente-perfil';
import { AdminService } from '../service/admin.service';
import { AuthService } from 'src/app/security/auth.service';
import { ClientePerfilDetallado } from 'src/app/class/cliente/cliente-perfil-detallado';
import { UpdateClienteDialogComponent } from '../clientDialog/update-cliente-dialog/update-cliente-dialog.component';
import { NuevoClienteDialogComponent } from '../clientDialog/nuevo-cliente-dialog/nuevo-cliente-dialog.component';
import { EventData } from 'src/app/security/event.class';
import { BilleteraDTO } from 'src/app/class/billetera/billetera-dto';
import { BilleteraDTOClienteDialogoComponent } from '../clientDialog/billetera-dtocliente-dialogo/billetera-dtocliente-dialogo.component';
import { MovimientoDTO } from 'src/app/class/movimiento/movimiento-dto';
import { MovimientosComponent } from '../clientDialog/movimientos/movimientos.component';
import { BalanceDTO } from 'src/app/class/balance/balance-dto';
import { BalanceComponent } from '../clientDialog/balance/balance.component';
import { PlazoFijoDTO } from 'src/app/class/plazoFijo/plazoFijo-dto';
import { PlazoFijoComponent } from '../clientDialog/plazo-fijo/plazo-fijo.component';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})
export class AdminHomeComponent implements OnInit {
  content?: string;
  columnas: string[] = [ 'email', 'nombreCompleto', 'editar','billetera','historialInversion','historialBalance', 'plazoFijo'];

  clientes: ClientePerfil[] = [];

  @ViewChild(MatTable) tabla1!: MatTable<ClientePerfil>;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort!: MatSort;

  dataSource!: MatTableDataSource<ClientePerfil>;

  constructor(private adminService: AdminService, private eventBusService: EventBusService
    ,public dialog: MatDialog ,private authService: AuthService) { }
  ngOnInit() {
    registerLocaleData( es );
    this.adminService.getClientes().subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.content = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (data) => {
          this.content = "Tabla cargada";
          this.clientes = data;
          this.dataSource = new MatTableDataSource<ClientePerfil>(this.clientes);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
          this.tabla1.renderRows();
          this.reloadTable();},     // nextHandler
    }
    );
  }

  filtrar(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filtro.trim().toLowerCase();
    this.content = "Tabla filtrada por : "+ filtro.trim().toLowerCase();
  }

  editarClientePerfil(email: string) {
    this.adminService.getClientePerfilByEmail(email).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.content = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (data) => {
          this.content = "Se abrio el dialogo para editar el perfil de "+email;
          this.abrirDialogoEditarClientePerfil(data);
          this.reloadTable();},     // nextHandler
    }

    );
  }
  billeteraDelCliente(email: string) {
    this.adminService.getBilleteraDelClienteByEmail(email).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.content = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (data) => {
          this.content = "Se abrio el dialogo de la billetera de "+email;
          this.abrirDialogoBilleteraDelCliente(data,email);
          this.reloadTable();},     // nextHandler
    }

    );
  }

  reloadTable(){
    this.adminService.getClientes().subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.content = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (data) => {
          this.content += "";
          this.clientes = data;
          this.dataSource = new MatTableDataSource<ClientePerfil>(this.clientes);
          this.dataSource.sort = this.sort;
          this.dataSource.paginator = this.paginator;
        },     // nextHandler
    }
    );
  }
  reloadTable1(){
    this.reloadTable();
  }
  agregarCliente(email:string,nombre:string,apellido:string,password:string,confrimPassword:string) {
    this.authService.register(email,nombre,apellido,password,confrimPassword).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.content = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (data) => {

          this.content = data.mensaje;
           console.log(data);
           this.reloadTable();},     // nextHandler
    }
    );
  }

  updateClientePerfil(updateCliente:ClientePerfilDetallado) {
    this.adminService.updateClientePerfil(updateCliente).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.content = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (data) => {
          this.content = data;
          console.log(data);
          this.reloadTable();},     // nextHandler
    }
    );
  }

  abrirDialogo() {
    const dialogo1 = this.dialog.open(NuevoClienteDialogComponent, {
      data: {
        email:'',
        nombre:'',
        apellido:'',
        password:'',
        confirmPassword:''
      },
      width: '60vw',
      maxWidth: '90vw',
    });
    this.content = "se abrio el dialogo para reguistrar un nuevo cliente";
    dialogo1.afterClosed().subscribe(nuevoCliente => {
      if (nuevoCliente != undefined)
      this.agregarCliente(nuevoCliente.email,nuevoCliente.nombre,nuevoCliente.apellido,
        nuevoCliente.password,nuevoCliente.confirmPassword);
      this.reloadTable();
    });
  }
  abrirDialogoEditarClientePerfil(data: ClientePerfilDetallado) {

    const dialogo = this.dialog.open(UpdateClienteDialogComponent, {
      data: data
    });
    dialogo.afterClosed().subscribe(cliente => {
      if (cliente != undefined)
      this.updateClientePerfil(cliente);
      this.reloadTable();
    });
  }

  abrirDialogoBilleteraDelCliente(billetera: BilleteraDTO[],email:string) {

    const dialogo = this.dialog.open(BilleteraDTOClienteDialogoComponent, {
      data: {billetera
        , compraVentaDeMoneda:{cliente:email,moneda:'',cantidad:0,montoUsd:0},
        deposito: {email:email,monto:0},inversion: {
          cliente:email,
          cantidad:0,
          monto:0,
          inversion:[
            {moneda:'',cantidad:0,montoUsd:0},
            {moneda:'',cantidad:0,montoUsd:0}
          ]
        }
      }
    });
    dialogo.afterClosed().subscribe(() => {
      this.reloadTable();
    });
  }
  verMovimientos(email:string){
    this.adminService.getMovimientosDelClienteByEmail(email).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.content = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (data) => {
          this.content = "Se abrio el dialogo de la movimientos de "+email;
          this.abrirDialogoMovimientosDelCliente(data,email);
          this.reloadTable();     // nextHandler
        }
      }
    );

  }
  abrirDialogoMovimientosDelCliente(movimiento: MovimientoDTO[],email:string) {

    const dialogo = this.dialog.open(MovimientosComponent, {
      data: {movimiento,
          cliente:email

      }
    });
    dialogo.afterClosed().subscribe(() => {
      this.reloadTable();
    });
  }

  verBalance(email:string){
    this.adminService.getBalancesDelClienteByEmail(email).subscribe(
      {
        complete: () => {  }, // completeHandler
        error: (err) => {
          this.content = err.error.message || err.error || err.message;

          if (err.status === 403)
            this.eventBusService.emit(new EventData('logout', null)); },    // errorHandler
        next: (data) => {
          this.content = "Se abrio el dialogo de la balances de "+email;
          this.abrirDialogoBalancesDelCliente(data,email);
          this.reloadTable();     // nextHandler
        }
      }
    );

  }
  abrirDialogoBalancesDelCliente(balance: BalanceDTO[],email:string) {

    const dialogo = this.dialog.open(BalanceComponent, {
      data: {balance,
          cliente:email

      },
      width: '90vw',
      maxWidth: '90vw'
    });
    dialogo.afterClosed().subscribe(() => {
      this.reloadTable();
    });
  }
  abrirDialogoPlazosFijosDelCliente(email:string) {

    const dialogo = this.dialog.open(PlazoFijoComponent, {
      data: {cliente:email}
    });
    dialogo.afterClosed().subscribe(() => {
      this.reloadTable();
    });
  }
}
