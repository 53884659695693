import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BilleteraDTO } from 'src/app/class/billetera/billetera-dto';
import { BalanceDTO } from 'src/app/class/balance/balance-dto';
import { MovimientoDTO } from 'src/app/class/movimiento/movimiento-dto';
import { DetalleMovimientoDTO } from 'src/app/class/detalleMobimiento/detalle-movimiento-dto';
import { PlazoFijoDTO } from 'src/app/class/plazoFijo/plazoFijo-dto';
import { MovPlazoFijoDTO } from 'src/app/class/plazoFijo/movPlazoFijo-dto';
import { environment } from 'src/environments/environment';

const API_URL =  environment.backendURL+'/api/client';

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  constructor(private http: HttpClient) { }



  getBilleteraDelCliente() {
    return this.http.get<BilleteraDTO[]>(API_URL + '/billetera', { responseType: 'json' });
  }

  getMontoInvertido() {
    return this.http.get<number>(API_URL + '/montoInvertido', { responseType: 'json' });
  }
  getMovimientosDelCliente(){
    return this.http.get<MovimientoDTO[]>(API_URL +'/movimientos', { responseType: 'json' });
  }
  getDetallesMovimientosDelCliente(movimientoId:number){
    return this.http.get<DetalleMovimientoDTO[]>(API_URL +'/movimientos/'+movimientoId, { responseType: 'json' });
  }
  getBalancesDelCliente(){
    return this.http.get<BalanceDTO[]>(API_URL +'/balance', { responseType: 'json' });
  }

  // Plazo Fijo

  getPlazosFijosDelCliente(){
    return this.http.get<PlazoFijoDTO[]>(API_URL +'/plazo_fijo', { responseType: 'json' });
  }
  getMovPlazoFijoDelCliente(){
    return this.http.get<MovPlazoFijoDTO[]>(API_URL +'/plazo_fijo/movimientos_plazo_fijo', { responseType: 'json' });
  }
}
