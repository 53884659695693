<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>
      <h1 mat-dialog-title>Movimientos del cliente {{data.cliente}}</h1>

    </mat-card-title>
    <mat-card-subtitle style="color: white">
      <h1 mat-dialog-title>{{movimientoMsj}}</h1>
      <div *ngIf="detalleMovimientoSelected[0].moneda!=''"
        [ngStyle]="{'background-color': detalleMovimientoSelected[0].transaccion === 'INGRESO' ? '#00ff9811' : '#ff005f11'}">
        <mat-form-field class="fourFieldRow">
          <input matInput [(ngModel)]="detalleMovimientoSelected[0].moneda" type="text" placeholder="Moneda"
            [readonly]="isModificarDetalle1AndIsCompraOventa">
        </mat-form-field>
        <mat-form-field class="fourFieldRow">
          <input matInput [(ngModel)]="detalleMovimientoSelected[0].cantidad" type="number" placeholder="Cantidad"
            [readonly]="isModificarDetalle1AndIsCompraOventa">
        </mat-form-field>
        <mat-form-field class="fourFieldRow">
          <input matInput [(ngModel)]="detalleMovimientoSelected[0].precio" type="number" placeholder="Precio" readonly>
        </mat-form-field>
        <mat-form-field class="fourFieldRow">
          <input matInput [(ngModel)]="detalleMovimientoSelected[0].montoUsd" type="number" placeholder="MontoUsd"
            [readonly]="isModificarDetalle1">
        </mat-form-field>
      </div>
      <div *ngIf="detalleMovimientoSelected[1].moneda!='' && detalleMovimientoSelected[1].moneda!=null "
        [ngStyle]="{'background-color': detalleMovimientoSelected[1].transaccion === 'INGRESO' ? '#00ff9811' : '#ff005f11'}">
        <mat-form-field class="fourFieldRow">
          <input matInput [(ngModel)]="detalleMovimientoSelected[1].moneda" type="text" placeholder="Moneda"
            [readonly]="isNotModificarDetalle1AndIsCompraOventa">
        </mat-form-field>
        <mat-form-field class="fourFieldRow">
          <input matInput [(ngModel)]="detalleMovimientoSelected[1].cantidad" type="number" placeholder="Cantidad"
            [readonly]="isNotModificarDetalle1AndIsCompraOventa">
        </mat-form-field>
        <mat-form-field class="fourFieldRow">
          <input matInput [(ngModel)]="detalleMovimientoSelected[1].precio" type="number" placeholder="Precio" readonly>
        </mat-form-field>
        <mat-form-field class="fourFieldRow">
          <input matInput [(ngModel)]="detalleMovimientoSelected[1].montoUsd" type="number" placeholder="MontoUsd"
            [readonly]="!isModificarDetalle1">
        </mat-form-field>

      </div>
      <div *ngIf="mostrarBotonModificar" class="boton-modificar-container">
        <button mat-button style="color: white; margin: 10px"  (click)="modificarDetalleMovimiento()">Modificar Movimiento</button>
      </div>
      <h1 mat-dialog-title>{{movimientoModificarMSJ}}</h1>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>


    <mat-form-field>
      <mat-label>Buscar</mat-label>
      <input matInput (keyup)="filtrar($event)" placeholder="buscar">
    </mat-form-field>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" #tablaMovimiento matSort
      matSortActive="movimientoId" matSortDirection="desc" matSortDisableClear>

      <!--- Note that these columns can be defined in any order.
                The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="movimientoId">
        <th mat-header-cell *matHeaderCellDef> Mov. ID </th>
        <td mat-cell *matCellDef="let movimiento"> {{movimiento.movimientoId}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="balanceId">
        <th mat-header-cell *matHeaderCellDef> Bal. ID </th>
        <td mat-cell *matCellDef="let movimiento"> {{movimiento.balanceId}} </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="fecha">
        <th mat-header-cell *matHeaderCellDef> Fecha</th>
        <td mat-cell *matCellDef="let movimiento"> {{movimiento.fecha}} </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="transaccion">
        <th mat-header-cell *matHeaderCellDef> Transaccion </th>
        <td mat-cell *matCellDef="let movimiento"> {{movimiento.transaccion}} </td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="monto">
        <th mat-header-cell *matHeaderCellDef> Monto </th>
        <td mat-cell *matCellDef="let movimiento"> {{movimiento.monto | currency: 'USD' | lowercase}} </td>
      </ng-container>


      <!-- Name Column -->
      <ng-container matColumnDef="ver">
        <th mat-header-cell *matHeaderCellDef> Ver </th>
        <td mat-cell *matCellDef="let movimiento"> <button mat-button  focusable="false"
            (click)="getDetallesMovimientos(movimiento)">
            Ver
          </button> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsMovimiento"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsMovimiento;"></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[5, 10, 15]" showFirstLastButtons></mat-paginator>


  </mat-card-content>
  <mat-card-footer>
    <mat-card-actions>
      <button mat-button  (click)="relogearDatos()"><i class="fa fa-refresh"></i></button>
      <button mat-button  (click)="cancelar()">Cerrar</button>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>