import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminHomeComponent } from './admin/admin-home/admin-home.component';
import { ClienteBalanceComponent } from './cliente/cliente-balance/cliente-balance.component';
import { ClienteBilleteraComponent } from './cliente/cliente-billetera/cliente-billetera.component';
import { ClienteDetalleMovimientoComponent } from './cliente/cliente-detalle-movimiento/cliente-detalle-movimiento.component';
import { ClienteMovimientosComponent } from './cliente/cliente-movimientos/cliente-movimientos.component';
import { ClientePlazoFijoComponent } from './cliente/cliente-plazo-fijo/cliente-plazo-fijo.component';
import { HomeComponent } from './public/home/home.component';
import { LoginComponent } from './public/login/login.component';
import { RegisterComponent } from './public/register/register.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'login', component: LoginComponent },
  { path: 'admin', component: AdminHomeComponent },
  { path: 'cliente', component: ClienteBilleteraComponent },
  { path: 'clienteDetalleMovimiento', component: ClienteDetalleMovimientoComponent },
  { path: 'clienteMovimiento', component: ClienteMovimientosComponent },
  { path: 'clienteBalance', component: ClienteBalanceComponent },
  { path:'clientePlazoFijo',component:ClientePlazoFijoComponent},
  { path: '**', redirectTo: 'home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
