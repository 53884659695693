<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>
      <h1 mat-dialog-title>Total: ${{total| number:'1.2-2':'es'}}
        <button mat-stroked-button color="success" (click)="modificarDetalleMovimiento()">Cerrar Balance</button>
      </h1>

    </mat-card-title>
    <mat-card-subtitle>
      <h2>Cambiando el total de cada moneda modificas su precio.</h2>
      <h2>Estas monedas no se van a vender a usdt solo se van a cotizar para hacer el cierre </h2>
      <h2>Si queres hacer un cambio en la cantidad tenes que hacer un movimiento previo.</h2>
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

    <h1 mat-dialog-title>{{buscarPrecio}}</h1>


    <ng-template ngFor let-moneda [ngForOf]="billetera" let-i="index" let-odd="odd">
      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="moneda.moneda" type="text" placeholder="Moneda" readonly>
      </mat-form-field>
      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="moneda.cantidad" type="number" placeholder="Cantidad" readonly>
      </mat-form-field>
      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="moneda.precio" type="number" placeholder="Precio" readonly>
      </mat-form-field>
      <mat-form-field class="fourFieldRow">
        <input matInput [(ngModel)]="moneda.total" (ngModelChange)="changeMonto(moneda,$event)" type="number"
          placeholder="Total" [readonly]="moneda.moneda=='USDT'">
      </mat-form-field>
    </ng-template>


  </mat-card-content>
  <mat-card-footer>
    <mat-card-actions class="botones-cerrar-refresh">
      <button mat-stroked-button color="primary" (click)="relogearDatos()"><i class="fa fa-refresh"></i></button>
      <button mat-button color="primary" (click)="cancelar()">Cerrar</button>
    </mat-card-actions>
  </mat-card-footer>
</mat-card>