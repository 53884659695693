import { Component, OnInit, Inject, ViewChild, HostListener} from '@angular/core';
import { registerLocaleData } from '@angular/common';
import es from '@angular/common/locales/es';
import { MatTableDataSource } from '@angular/material/table';
import { MatTable } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { BalanceDTO } from 'src/app/class/balance/balance-dto';
import { ClientService } from '../service/cliente.service';
import { EventBusService } from 'src/app/security/event-bus.service';

@Component({
  selector: 'app-cliente-balance',
  templateUrl: './cliente-balance.component.html',
  styleUrls: ['./cliente-balance.component.css']
})
export class ClienteBalanceComponent implements OnInit {
  balanceMsj = "Estos son tus balances.";
  displayedColumnsBalance: string[] = [ 'fechaInicio', 'inicio', 'montoInvertido', 'capitalizacionBruta', 'balanceNeto','ver'];
  balance: BalanceDTO[] = [];
  balanceSelected: BalanceDTO = new BalanceDTO(0,'','',0,0,0,0,0,'');
  vTablet: number = 767;
  vComputadora: number = 1024;
  tablet: boolean = false;
  computadora: boolean = false;
  @ViewChild(MatTable) tablaBalance!: MatTable<BalanceDTO>;
  @ViewChild(MatPaginator, { static: true }) paginator!: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort!: MatSort;

  dataSource!: MatTableDataSource<BalanceDTO>;

  constructor(private clientService: ClientService, private eventBusService: EventBusService) {

  }

  ngOnInit() {
    this.relogearDatos();
    this.reloadTable();
    registerLocaleData(es);
    this.onResize();
  }

  relogearDatos() {
    this.clientService.getBalancesDelCliente().subscribe(
      {
        complete: () => { }, // completeHandler
        error: (err) => {
          this.balanceMsj = JSON.parse(err.error).message;
        },    // errorHandler
        next: (data) => {
          this.onResize();
          this.balance = data;
          this.reloadTable();     // nextHandler
        }
      }
    );
    registerLocaleData(es);
    this.reloadTable();
    this.onResize();
  }

  reloadTable() {
    this.dataSource = new MatTableDataSource<BalanceDTO>(this.balance);
    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
    this.onResize();

  }
  filtrar(event: Event) {
    const filtro = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filtro.trim().toLowerCase();
    this.balanceMsj = "Tabla filtrada por : " + filtro.trim().toLowerCase();
    this.onResize();
  }
  @HostListener('window:resize', ['$event'])
  onResize() {
    if( document.body.clientWidth >= this.vComputadora){
      this.tablet = false;
      this.computadora = true;
    }else if ( document.body.clientWidth >= this.vTablet){
      this.computadora = false;
      this.tablet = true;
    }else{
      this.computadora = false;
      this.tablet = false;
    }
  }

  selectBalance(balance:BalanceDTO){

    this.balanceSelected= balance;
  }
}
