        <mat-card class="example-card">
           <mat-card-header>
            <mat-card-title>
              <h2>{{cliente.email}} </h2>
            </mat-card-title>
            <mat-card-subtitle>
             <h4>Se creo el {{cliente.fechaDeCreacion}}.<br>
              Posee los roles: {{cliente.roles}}<br>
              {{cliente.fechaDeBaja!=''&&cliente.fechaDeBaja!=null ? ' Fue dado de baja el '+cliente.fechaDeBaja : 'Es una cuenta activa'}}.<br>
               {{cliente.fechaDeModificacion!=''&&cliente.fechaDeModificacion!=null ? ' Ultima actualizacion del perfil '+cliente.fechaDeModificacion : ''}}.<br>
              </h4>
            </mat-card-subtitle>
          </mat-card-header>

          <mat-card-content>

          <mat-form-field appearance="fill" class="twoFieldRow">
            <mat-label>DNI</mat-label>
            <input matInput [(ngModel)]="cliente.dni" type="number"  placeholder="DNI">
          </mat-form-field>
          <mat-form-field appearance="fill" class="twoFieldRow">
            <mat-label>Telefono</mat-label>
            <input matInput [(ngModel)]="cliente.telefono" type="number"   placeholder="Telefono">
          </mat-form-field>
          <mat-form-field appearance="fill" class="twoFieldRow">
            <mat-label>Nombre</mat-label>
            <input matInput [(ngModel)]="cliente.nombre" type="text"   placeholder="Nombre">
          </mat-form-field>
          <mat-form-field appearance="fill" class="twoFieldRow">
            <mat-label>Apellido</mat-label>
            <input matInput [(ngModel)]="cliente.apellido" type="text"  placeholder="Apellido">
          </mat-form-field>
          <mat-form-field appearance="fill" class="twoFieldRow">
            <mat-label>Password</mat-label>
            <input matInput [(ngModel)]="cliente.password" type="password"   placeholder="Password">
          </mat-form-field>



          </mat-card-content>

          <mat-card-actions align="end">
            <button mat-button  [mat-dialog-close]="cliente">Actualizar Datos</button>
            <button mat-stroked-button  (click)="eliminarCliente()">Eliminar Cliente</button>
            <button mat-stroked-button  (click)="cancelar()" >Cancelar</button>
          </mat-card-actions>

          <mat-card-footer>
           <h4 style="font-style: italic;">   Cambia los datos y actualiza el perfil</h4>
          </mat-card-footer>
        </mat-card>




