export class DetalleMovimientoDTO {
  detalleMovimientoId:number;
  movimientoId:number;
  fecha:string;
  transaccion:string;
  moneda:string;
  cantidad:number;
  precio:number;
  montoUsd:number;


  constructor( detalleMovimientoId:number, movimientoId:number, fecha: string,  transaccion: string,  moneda: string,
    cantidad: number,  precio: number,  montoUsd: number) {
    this.detalleMovimientoId =detalleMovimientoId;
    this.movimientoId =movimientoId;
    this.fecha =fecha;
    this.transaccion = transaccion;
    this.moneda = moneda;
    this.cantidad = cantidad;
    this.precio = precio;
    this.montoUsd = montoUsd;
  }
}
