export class CompraVentaDeMoneda {
  cliente:string;
  moneda:string;
  cantidad:number;
  montoUsd:number;


  constructor( cliente: string,  cantidad: number,  montoUsd: number,moneda:string) {
    this.cliente =cliente;
    this.moneda=moneda;
    this.cantidad = cantidad;
    this.montoUsd = montoUsd;
  }
}
