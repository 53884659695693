import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/security/auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  form: any = {
    email: null,
    nombre: null,
    apellido: null,
    password: null,
    confirmPassword: null
  };
  isSuccessful = false;
  isSignUpFailed = false;
  errorMessage = '';

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
  }

  onSubmit(): void {
    const { email, nombre, apellido, password,confirmPassword} = this.form;

    this.authService.register( email, nombre, apellido,
       password,confirmPassword).subscribe(
        {
          complete: () => {  }, // completeHandler
          error: (err) => {
            this.errorMessage = err.error.message;
            this.isSignUpFailed = true;  },    // errorHandler
          next: (data) => { console.log(data);
            this.isSuccessful = true;
            this.isSignUpFailed = false;  }     // nextHandler
      }
       );
  }




  registrarAdmin(): void{
    const { email, nombre, apellido, password,confirmPassword} = this.form;

    this.authService.registerAdmin( email, nombre, apellido,
       password,confirmPassword).subscribe(
        {
          complete: () => {  }, // completeHandler
          error: (err) => {
            this.errorMessage = err.error.message;
            this.isSignUpFailed = true;  },    // errorHandler
          next: (data) => { console.log(data);
            this.isSuccessful = true;
            this.isSignUpFailed = false;  },     // nextHandler
      }
       );
  }
}

